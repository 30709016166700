import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { userService } from "../../../services/CommonServices";
import Pagination from 'react-js-pagination';
import GlobalContext from "../../../context/GlobalContext";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";

const UserPropertiesList = (props) => {
    var global_data = useContext(GlobalContext)
    let history = useNavigate()
    const [properties_list, set_properties_list] = useState([])
    const [page, set_page] = useState(1)
    const [total_count, set_total_count] = useState(0)
    const [id, set_id] = useState("")
    const [search, set_search] = useState('')
    const [propertyType, setPropertyType] = useState('');
    const [status, setStatus] = useState("");

    let query = new URLSearchParams(window.location.search)

    useEffect(() => {
        var temp_page = query.get('page');
        set_page(Number(temp_page));
        if(query.get('search') != null) {
            set_search(query.get('search'));
        } else {
            set_search("");
        }
        
        setPropertyType(query.get("property_type"));
        setStatus(query.get("status"));
        set_id(query.get("id"));
        get_properties_list(query.get("id"),query.get("property_type"),query.get("status"),temp_page, query.get('search') != null ? query.get('search') : search);
    }, [page, global_data.stat, propertyType, status])

    function search_data(event) {
        if (page == 1) {
            get_properties_list(id,propertyType, status, page, search);
            handle_page_change(1);
        } else {
            set_page(1);
            handle_page_change(1);
        }
    }


    function get_properties_list(id, propertyType, status, page, search) {
        global_data.set_loader(true)
        userService.get_user_properties_list(global_data.token,id,propertyType, status, page, search).then(response => {
            if (response.status == 200) {
                set_properties_list(response.data?.data)
                set_total_count(response.data.totalItems)
                global_data.set_loader(false)
            }
        }).catch(err => {
            global_data.set_loader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                global_data.set_token('')
                global_data.set_is_logged_in("false")
                localStorage.clear()
                toast.info("Your session has been expired, please login again!..")
                history('/')
            }
        })
    }

    
    function handle_page_change(event) {
        set_page(event)
        if(search == '') {
            history(`/user_property?id=${id}&property_type=${propertyType}&status=${status}&page=${page}`)
        } else {
            history(`/user_property?id=${id}&property_type=${propertyType}&status=${status}&page=${page}&search=${search}`)
        }
    }

    function handlePropertyChange(event){
        history(`/user_property?id=${id}&property_type=${event.target.value}&status=${status}&page=1`)
        setPropertyType(event.target.value)
    }

    function handleStatusChange(event){
        history(`/user_property?id=${id}&property_type=${propertyType}&status=${event.target.value}&page=1`)
        setStatus(event.target.value)
    }

    function viewDetail (id){
        history(`/bid_detail?id=${id}`)
    }
    return (


        <div>
        <div className="userpropertylisting-bck-btn">
<button  className="back-btn edit-property-back-btn" type="button" 
>Back</button>
</div>
        <div class="content_area">

            <div class="tables_area">
                <div className="top-listing-header">
                <h2 class="pull-left" style={{marginRight:"auto"}}>User Properties Listing</h2>
               
                <Form.Select className="pull-right" size="lg" onChange={handlePropertyChange}>
                    <option value="sale" selected={propertyType=="sale"}>Sale</option>
                    <option value="auction" selected={propertyType=="auction"}>Auction</option>
                </Form.Select>
                
                <Form.Select className="pull-right" onChange={handleStatusChange}>
                <option value="all" selected={status=="all"}>All</option>
                    <option value="listed" selected={status=="listed"}>Listed</option>
                    <option value="under_review" selected={status=="under_review"}>Under Review</option>
                </Form.Select>
                
                <div class="pull-right searchbtn_btn">
                    <input type="text" placeholder="Search By Property Name" value={search} onChange={(event) => { set_search(event.target.value) }} />
                    <span>
                        <i class="fa fa-search" onClick={search_data}></i>
                    </span>
                </div>
                </div>
                <div class="clear"></div>
                {properties_list.length == 0 ? <h3 className="text-center" style={{ marginTop: "40px" }}>No Data Found</h3> :
                    <div class="white_box">
                        <div class="table-responsive">

                            <table width="100%" cellspacing="0" cellpadding="0">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Property Name</th>
                                        <th>Address</th>
                                        {/* <th>Role</th> */}
                                        <th>Added By</th>
                                        <th>Property Type</th>
                                        {propertyType=="auction"?<th>BIDS</th>:""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {properties_list.length > 0 && properties_list?.map((item, index) =>
                                        <tr>
                                            <td> {(20 * (page - 1)) + index + 1}</td>
                                            <td><a className="item_text" onClick={(event) => { history(`/edit_property/?id=${item.id}`, {state:item.bid_count}) }}>{item.name}</a></td>
                                            <td>{item.address}</td>
                                            <td>{item.added_by?.name + '(' + item.added_by?.email + ')'}</td>
                                            <td>{item.property_type=='sale'?"Sale":"Auction"}</td>
                                            
                                            {propertyType=="auction"? item.bid.length >0 ?<td><a className="text_size" onClick={()=>{viewDetail(item.id)}}>{item.bid.length}</a></td>:<td><a className="text_size">{item.bid.length}</a></td>:""}
                                        </tr>
                                    )}


                                </tbody>
                            </table>

                        </div>

                    </div>
                }
            </div>
                {properties_list.length != 0 &&
                    <div class="paginationrow">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={20}
                            totalItemsCount={total_count}
                            pageRangeDisplayed={5}
                            prevPageText="Previous"
                            nextPageText="Next"
                            onChange={(event) => { handle_page_change(event); }}
                        />

                    </div>}

        </div>
        </div>
        
    );
};
export default UserPropertiesList;