import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import validation from "../../container/Hoc/ValidationRules";
import GlobalContext from "../../context/GlobalContext";
import { userService } from '../../services/CommonServices';
import logo from '../../assets/images/logo.svg';

const Login = (props) => {

	const [email, set_email] = useState('');
	const [password, set_password] = useState('');
	var globalContext = useContext(GlobalContext)
	var loginData = useContext(GlobalContext)
	let history = useNavigate()

	useEffect(() => {
		validation();
	}, []);



	function login(event) {
		toast.dismiss()
		event.preventDefault();
		if (email.trim() == "") {
			toast.error("Email field can't be empty.");
			return false;
		}
		else if (!/^[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/.test(email.toLowerCase())) {
            toast.error("Invalid email")
            return false;
        }
		
		else if (password.trim() == "") {
			toast.error("Password field can't be empty.");
			return false;
		}
		else {
			globalContext.set_loader(true)
			var formData = {
				"email": email.toLowerCase(),
				"password": password
			}

			userService.login(formData).then(response => {
				
				if (response.status === 200) {
					console.log("ressss", response)
					globalContext.set_loader(false) 
					loginData.set_token(response.data.token)
					loginData.set_id(response.data.data.id)
					loginData.set_name(response.data.data.name)
					loginData.set_email(response.data.data.email)
					loginData.set_address(response.data.data.address)
					loginData.set_phone_number(response.data.data.phone_number)
					loginData.set_image(response.data.image)
					loginData.set_is_logged_in("true")

					sessionStorage.setItem('token', response.data.token)
					sessionStorage.setItem('name', response.data.data.name)
					sessionStorage.setItem('email', response.data.data.email)
					sessionStorage.setItem('address', response.data.data.address)
					sessionStorage.setItem('phone_number', response.data.data.phone_number)
					sessionStorage.setItem('image', response.data.data.image)
					sessionStorage.setItem("id", response.data.data.id)
					sessionStorage.setItem('is_logged_in', "true")
					toast.success('logged in successfully')

					history('/dashboard')

				} else {
					alert(response);
				}
			}).catch(function (err) {
				globalContext.set_loader(false)
				toast.error(err.response.data.message)
			})
		}

	}
	function forget_password(event){
		event.preventDefault()
		history('/forgot_password')

	}


	return (
        
		<div class="container glow-body">
			{/* <div class="logo_header text-center">
				<a href="#"><img src={logo} alt="" /></a>
			</div> */}
			<div class="login_form_outer">
				<div class="login_form">
				<a href="#" className="logo_row"><img src={logo} alt="" /></a>
					<h2 className="text-center">Sign In</h2>
					<div class="form-group">
						<label>Email</label>
						<input type="text" value={email} onChange={(event) => { set_email(event.target.value); }} placeholder="" class="form-control for_username" />
					</div>
					<div class="form-group">
						<label>Password</label>
						<input type="password" value={password} onChange={(event) => { set_password(event.target.value); }} placeholder="" class="form-control" />
					</div>
					{/* <p class="forgot_password" ><a href="" onClick={forget_password}>Forgot Password?</a></p> */}
					<a href="" class="blue_btn mb-4" onClick={login}>Sign In</a>
				</div>
				{/* <img src="images/shadow.png" alt="" class="shadow" /> */}
			</div>
		</div>
	);
};
export default Login;