import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import GlobalContext from "../../../context/GlobalContext";
import { userService } from "../../../services/CommonServices";

const ViewBid = () => {
    const globalData = useContext(GlobalContext)
    const [Detail, setDetail] = useState("")
    const [hightPrice, setHighPrice] = useState("");
    let query = new URLSearchParams(window.location.search)
    let navigate = useNavigate();

    const GetDetails = () => {
        userService.get_properties_detail(globalData.token, query.get("id")).then(res => {
            if (res.status === 200) {
                globalData.set_loader(false)
                setDetail(res.data.data)
            }
        }).catch(err => {
            globalData.set_loader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                globalData.set_token('')
                globalData.set_is_logged_in("false")
                localStorage.clear()
                toast.info("Your session has been expired, please login again!..")
                navigate('/')
            }
        })
    }
    useEffect(() => {
        globalData.set_loader(true)
        GetDetails()
    }, [hightPrice])

    let socket = new WebSocket(`wss:https://api.realzon.net/ws/bid/${query.get("id")}/`)
    socket.onmessage=(event)=>{
        setHighPrice(JSON.parse(event.data).bid_price)
    }
    return (
        <div className="content padding_section">
            {Detail !=="" &&
                Detail?.bid.map((bid_detail)=>(
                <div className="main-form px-2">
                    <div className="box-border">
                    <h3>{`${Detail.name}'s Detail`}</h3>

<button onClick={()=>{navigate(-1)}} className="back-btn" type="button" 
>Back</button>
  
                    </div>
                    <div className="row mt-4">
                        <aside className="col-md-4 mt-4">
                            <div className="view-content">
                                <p className="mb-0">Name</p>
                                <h5 className="mt-2">{bid_detail.user.name}</h5>
                            </div>
                        </aside>
                        <aside className="col-md-4 mt-4">
                            <div className="view-content">
                                <p className="mb-0">Email</p>
                                <h5 className="mt-2">{bid_detail.user.email}</h5>
                            </div>
                        </aside>
                        <aside className="col-md-4 mt-4">
                            <div className="view-content">
                                <p className="mb-0">Email</p>
                                <h5 className="mt-2">{bid_detail.user.user_type=="agent"?"Agent":bid_detail.user.user_type=="seller"?"Seller":bid_detail.user.user_type=="customer"?"Customer":"Admin"}</h5>
                            </div>
                        </aside>
                        <aside className="col-md-4 mt-4">
                            <div className="view-content">
                                <p className="mb-0">Email</p>
                                <h5 className="mt-2">{bid_detail.user.phone_number}</h5>
                            </div>
                        </aside>
                        <aside className="col-md-4 mt-4">
                            <div className="view-content">
                                <p className="mb-0">Bid Price</p>
                                <h5 className="mt-2">{Number(bid_detail.price).toLocaleString("en-US")}</h5>
                            </div>
                        </aside>
                    </div>
                </div>
               
                ))
            }
        </div>
    );
}
export default ViewBid;