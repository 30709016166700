import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useHistory } from "react-router-dom";
import { userService } from "../services/CommonServices";
import Pagination from 'react-js-pagination';
import Swal from "sweetalert2";
import GlobalContext from "../context/GlobalContext";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import moment from "moment";
import DatePicker from 'react-date-picker';

const TransactionHistory = (props) => {
    var global_data = useContext(GlobalContext)
    let query = new URLSearchParams(window.location.search)
    let history = useNavigate()
    const [data, setData] = useState([])
    const [page, set_page] = useState(1)
    const [total_count, set_total_count] = useState(0)

    const [search, set_search] = useState(query.get('search')!=null?query.get('search'):'')
    const [transactionType, setTransactionType] = useState(query.get("transactionType"));
    const [start_date, set_start_date] = useState(query.get("start_date")!=null?query.get("start_date"):"")
    const [end_date, set_end_date] = useState(query.get("end_date")!=null?query.get("end_date"):"")


    useEffect(() => {
        var temp_page = query.get('page');
        set_page(Number(temp_page));
        get_properties_list(query.get("transactionType"),temp_page, query.get("start_date"), query.get("end_date"), query.get("search"));
    }, [page, global_data.stat, transactionType, end_date])

    function search_data(event) {
        if (page == 1) {
            get_properties_list(transactionType, page, start_date, end_date, search);
            handle_page_change(1);
        } else {
            set_page(1);
            handle_page_change(1);
        }
    }


    function get_properties_list(transactionType, page, start_date, end_date,search) {
        global_data.set_loader(true)
        userService.get_transaction_history(global_data.token,transactionType, page, start_date, end_date, search).then(response => {
            if (response.status == 200) {
                setData(response.data?.data)
                set_total_count(response.data.totalItem)
                global_data.set_loader(false)
            }
        }).catch(err => {
            global_data.set_loader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                global_data.set_token('')
                global_data.set_is_logged_in("false")
                localStorage.clear()
                toast.info("Your session has been expired, please login again!..")
                history('/')
            }
        })
    }


    function handle_page_change(event) {
        set_page(event)
        history(`/transaction_history?transactionType=${transactionType}&page=${event}&search=${search}&start_date=${start_date}&end_date=${end_date}`)
    }

    function handleTransactionChange(event){
        history(`/transaction_history?transactionType=${event.target.value}&page=1&search=${search}&start_date=&end_date=`)
        setTransactionType(event.target.value)
    }
    function startDate(event){
        set_start_date(event)
    }

    function endDate(event){
        if (event==null){
            set_end_date("")
            history(`/transaction_history?transactionType=${transactionType}&page=1&search=${search}&start_date=${moment(start_date).format("YYYY-MM-DD")}&end_date=`)
        }
        else if(start_date == '' || start_date == null){
            set_end_date('')
            alert("please select start date")
        }
        else if(start_date > event){
            set_end_date('')
            alert("End Date should be greater than start date.")
        }   
        else{
        set_end_date(event)
        history(`/transaction_history?transactionType=${transactionType}&page=1&search=${search}&start_date=${moment(start_date).format("YYYY-MM-DD")}&end_date=${event != null?moment(event).format("YYYY-MM-DD"):''}`)
        }
    }

    return (

        <div class="content_area">

            <div class="tables_area">
                <div className="top-listing-header">
                <h2 class="pull-left" style={{marginRight:"auto"}}>Transaction History</h2>
                <Form.Select className="pull-right" size="lg" onChange={handleTransactionChange}>
                    <option value="entryFee" selected={transactionType=="EntryFee"}>Entry Fee</option>
                    <option value="subscription" selected={transactionType=="subscription"}>Subscription Fee</option>
                </Form.Select>
                {/* <DatePicker onChange={(event) => { startDate(event) }} format="y-MM-dd" value={start_date} yearPlaceholder="yyyy" monthPlaceholder="mm" dayPlaceholder="dd" /> */}
                <div class="pull-right">
                    <p>Start Date</p>
                    <DatePicker onChange={(event) => { startDate(event) }} value={start_date} format="y-MM-dd" maxDate={new Date()}  yearPlaceholder="yyyy" monthPlaceholder="mm" dayPlaceholder="dd" />
                    {/* <input type="date" max={moment(new Date()).format("YYYY-MM-DD")} value={start_date} onChange={(event) => { startDate(event.target.value) }} /> */}
                </div>
                <div class="pull-right">
                    <p>End Date</p>
                    <DatePicker onChange={(event) => { endDate(event) }} format="y-MM-dd" value={end_date}  maxDate={new Date()} yearPlaceholder="yyyy" monthPlaceholder="mm" dayPlaceholder="dd" />
                    {/* <input type="date" max={moment(new Date()).format("YYYY-MM-DD")} value={end_date} onChange={(event) => { endDate(event.target.value) }} /> */}
                </div>
                <div class="pull-right searchbtn_btn">
                    <input type="text" placeholder="Search By Transaction Id" value={search} onChange={(event) => { set_search(event.target.value) }} />
                    <span>
                        <i class="fa fa-search" onClick={search_data}></i>
                    </span>
                </div>
                </div>
                <div class="clear"></div>
                {data.length == 0 ? <h3 className="text-center" style={{ marginTop: "40px" }}>No Data Found</h3> :
                    <div class="white_box">
                        <div class="table-responsive">

                            <table width="100%" cellspacing="0" cellpadding="0">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Transaction Id</th>
                                        <th>Amount</th>
                                        <th>Date & Time</th>
                                        <th>User</th>
                                        {transactionType=="entryFee"?<th>Property</th>:""}
                                      {transactionType=="subscription"?<th>Plan</th>:""}
                                      {transactionType=="subscription"?<th>Platform</th>:""}
                                      {transactionType=="subscription"?<th>Valid From</th>:""}
                                      {transactionType=="subscription"?<th>Valid To</th>:""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 && data?.map((item, index) =>
                                        <tr>
                                            <td> {(20 * (page - 1)) + index + 1}</td>
                                            <td>{item.transaction_id}</td>
                                            <td>{transactionType=="subscription"?item.plan?.currency_code:"$"}{Number(item.amount).toLocaleString('en-US')}</td>
                                            <td>{moment(item.created_at).format("YYYY-MM-DD hh:mm a")}</td>
                                            <td>{item.user.name}</td>
                                            {transactionType=="entryFee"?<td>{item.property?.name}</td>:""}
                                            {transactionType=="subscription"?<td>{item.plan?.name}</td>:""}
                                            {transactionType=="subscription"?<td>{item.plan?.device=="web"?"Website":item.plan?.device=="ios"?"iOS":item.plan?.device=="android"?"Android":""}</td>:""}
                                            {transactionType=="subscription"?<td>{moment(item.start_date).format("YYYY-MM-DD")} {moment(item.start_date).format("hh:mm a")}</td>:""}
                                            {transactionType=="subscription"?<td>{moment(item.end_date).format("YYYY-MM-DD")} {moment(item.end_date).format("hh:mm a")}</td>:""}

                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        </div>

                    </div>
                }
            </div>
                {data.length != 0 &&
                    <div class="paginationrow">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={20}
                            totalItemsCount={total_count}
                            pageRangeDisplayed={5}
                            prevPageText="Previous"
                            nextPageText="Next"
                            onChange={(event) => { handle_page_change(event); }}
                        />

                    </div>}

        </div>
    );
};
export default TransactionHistory;