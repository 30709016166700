import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/CommonServices";
import GlobalContext from "../../../context/GlobalContext";
import Pagination from 'react-js-pagination';


const ContactUsList = (props) => {

    let history = useNavigate()
    let query = new URLSearchParams(window.location.search)
    let global_data = useContext(GlobalContext)
    const [page, set_page] = useState(query.get('page'))
    const [count, set_count] = useState(0)
    const [contact_us_list, set_contact_us_list] = useState([])

    useEffect(() => {
        set_page(query.get('page'))
        contactUsData(query.get('page'))
    }, [page])

    function view_contact_us(event, id) {
        event.preventDefault();
        history('/view_contact_us?id=' + id)
    }

    function contactUsData (page){
        global_data.set_loader(true)
        userService.get_contact_us_list(global_data.token,page).then(response => {
            if (response.status == 200) {
                global_data.set_loader(false)
                set_contact_us_list(response.data.data)
                set_count(response.data.totalItems)
            }
        })
    }

    function handle_page_change(event) {
        set_page(event)
        history(`/contact_us?page=${event}`)

    }

    return (

        <div class="content_area">

            <div class="tables_area">
                <h2 class="pull-left">Conatct Us List</h2>
                <div class="clear"></div>
                {contact_us_list.length == 0 ? <h3 className="text-center" style={{ marginTop: "40px" }}>No Data Found</h3> :
                    <div class="white_box">
                        <div class="table-responsive">
                            <table width="100%" cellspacing="0" cellpadding="0">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Contact Number</th>
                                        <th>Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contact_us_list.length > 0 && contact_us_list.map((item, index) =>
                                        <tr>
                                            <td>{(20 * (page - 1)) + index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.contact_number}</td>
                                            <td>{item.message}</td>
                                        </tr>
                                    )}


                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
            {contact_us_list.length != 0 &&
                <div class="paginationrow">
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={20}
                        totalItemsCount={count}
                        pageRangeDisplayed={5}
                        prevPageText="Previous"
                        nextPageText="Next"
                        onChange={(event) => { handle_page_change(event); }}
                    />

                </div>}


        </div>
    );
};
export default ContactUsList;