import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import validation from "../../../container/Hoc/ValidationRules";
import GlobalContext from "../../../context/GlobalContext";
import { userService } from "../../../services/CommonServices";

const ViewSeller = (props) => {
    let history = useNavigate()
    const [image, set_image] = useState('')
    const [picture, set_picture] = useState('')
    const [states_list, set_states_list] = useState([])
    const [address_cities_list, set_address_cities_list] = useState([])
    const [email, set_email] = useState('')
    const [phone_number, set_phone_number] = useState('')
    const [first_name, set_first_name]=useState('')
    const [last_name, set_last_name]=useState('')
    const [address, set_address]=useState('')

    const [address_state, set_address_state] = useState('')
    const [address_city, set_address_city] = useState('')
    const [address_pin_code, set_address_pin_code] = useState('')
    const [dob, set_dob] = useState('')
    
    var global_data = useContext(GlobalContext)

    var id = 0;
    
    useEffect(() => {
        validation()
        get_states_list();
        id = new URLSearchParams(window.location.search).get("id");
        get_user_by_id();
    }, [])

    function get_states_list() {
        userService.get_states_list().then(response => {
           if (response.status === 200) {
               set_states_list(response.data);
           }
        }).catch(err => {

            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                global_data.set_token('')
                global_data.set_is_logged_in("false")
                localStorage.clear()
                toast.info("Your session has been expired, please login again!..")
                history('/')
            }
        })
    }

    function get_user_by_id() {
        id &&
            userService.get_user_by_id(id).then(response => {
                if (response.status === 200) {
                    set_first_name(response.data.first_name);
                    set_last_name(response.data.last_name);
                    set_email(response.data.email);
                    set_phone_number(response.data.phone_number);
                    set_address(response.data.address);
                    set_address_state(response.data.address_state);
                    if(response.data.address_state != null && response.data.address_state != "") {
                        get_city_list_by_state(response.data.address_state);
                    }
                    set_address_city(response.data.address_city);
                    set_address_pin_code(response.data.address_pin_code);
                    set_dob(response.data.dob);
                    set_image(global_data.media_url + response.data.image);
                    
                } else {
                    alert(response);
                }
            }).catch(err => {
                toast.error(err.response.data.error)
            })
    }

    function address_state_change_handler(event) {
        set_address_city("");
        set_address_cities_list([]);
        if (event.target.value == "") {
            set_address_state("");
        } else {
            set_address_state(event.target.value);
            get_city_list_by_state(event.target.value);
        }
    }

    function get_city_list_by_state(state_id) {
        userService.get_city_list_by_state(state_id).then(response => {
            if (response.status == 200) {
                set_address_cities_list(response.data);
            }
        }).catch(err => { console.log({ err }) })
    }

    function address_city_change_handler(event) {
        set_address_city(event.target.value);
    }

    return (
        <div class="content_area">
            <div class="tables_area">
                <h2>View User</h2>
                <div class="white_box">
                    <div class="add_formarea">
                        <div class="row">
                            <aside class="col-md-12">
                                <div class="upload-profile">
                                    <img src={image} alt="" />
                                    <input type="file" disabled class="upload-input" accept="image/*"></input>
                                    {image == '' ? <i class="upload-icon fa fa-camera" aria-hidden="true"></i> : ''}
                                </div>
                                <p class="add-text text-dark text-center mt-5">View Profile</p>
                            </aside>
                        </div>
                        <div class="row form-box">
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase ">First Name</label>
                                    <input type="text" disabled maxLength={15} value={first_name} placeholder="Enter First Name" class="form-control for_name " onChange={(e) => { set_first_name(e.target.value) }} />
                                </div>
                            </aside>
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase ">Last Name</label>
                                    <input type="text" disabled maxLength={15} value={last_name} placeholder="Enter Last Name" class="form-control for_name" onChange={(e) => { set_last_name(e.target.value) }} />
                                </div>
                            </aside>
                        </div>
                        <div class="row">
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase ">Email</label>
                                    <input type="email" disabled value={email} placeholder="Enter Email" class="form-control for_username" onChange={(e) => { set_email(e.target.value) }} />
                                </div>
                            </aside>
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase ">Phone Number</label>
                                    <input type="text" disabled value={phone_number} placeholder="Enter Phone Number" minLength='1' maxLength="10" class="form-control for_numeric" onChange={(e) => { set_phone_number(e.target.value) }} />
                                </div>
                            </aside>
                        </div>
                        <div class="row">
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase ">Address</label>
                                    <input type="text" disabled value={address} placeholder="Enter Address" class="form-control " onChange={(e) => { set_address(e.target.value) }} />
                                </div>
                            </aside>

                            <aside class="col-sm-6">
                                <div class="form-group hide-btn">
                                    <label class="text-uppercase ">Address State</label>
                                    <select class="form-control" disabled value={address_state} onChange={address_state_change_handler}>
                                        <option value="">--Select State--</option>
                                        {states_list.map((state, index) =>
                                                <option value={state.id}>{state.name}</option>
                                        )}
                                    </select>
                                </div>
                            </aside>
                        </div>
                        <div class="row">

                            <aside class="col-sm-6">
                                <div class="form-group hide-btn">
                                    <label class="text-uppercase ">Address City</label>
                                    <select class="form-control" disabled value={address_city} onChange={address_city_change_handler}>
                                        <option value="">--Select City--</option>
                                        {address_cities_list.map((city, index) =>
                                                <option value={city.id}>{city.name}</option>
                                        )}
                                    </select>
                                </div>
                            </aside>

                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase ">Address Pin Code</label>
                                    <input type="text" disabled value={address_pin_code} placeholder="Enter Pin Code" class="form-control " onChange={(e) => { set_address_pin_code(e.target.value) }} />
                                </div>
                            </aside>
                        </div>
                        <div class="row">

                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase ">DOB</label>
                                    <input type="date" disabled placeholder="yyyy-mm-dd" value={dob} class="form-control" onChange={(e) => set_dob(e.target.value)} />
                                </div>
                            </aside>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ViewSeller;