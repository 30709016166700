import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import validation from "../../../container/Hoc/ValidationRules";
import GlobalContext from "../../../context/GlobalContext";

const SideMenu = (props) => {
    const [current_tab, set_current_tab] = useState(window.location.href.split("/")[3].split("?")[0])
    useEffect(() => {
        validation();
        set_current_tab(window.location.href.split("/")[3].split("?")[0])
    }, [window.location.href.split("/")[3].split("?")[0]])

    let history = useNavigate()

    function dashboard() {
        set_current_tab("dashboard");
        history('/dashboard')
    }

    function agents() {
        set_current_tab("agents");
        history('/agents?page=1')
    }

    function sellers() {
        set_current_tab("sellers");
        history('/sellers?page=1')
    }

    function customers() {
        set_current_tab("customers");
        history('/customers?page=1')
    }

    function properties() {
        set_current_tab("properties");
        history('/properties?my_property=false&property_type=sale&auction_type=online&status=all&page=1')
    }

    function interest() {
        set_current_tab("interests");
        history('/interests?property_type=auction&page=1&search=')
    }

    function partner() {
        set_current_tab("partner");
        history('/partner?type=attorney&page=1')
    }

    function contact() {
        set_current_tab("contact_us");
        history('/contact_us?page=1')
    }

    function transaction() {
        set_current_tab("transaction_history");
        history('/transaction_history?transactionType=subscription&page=1&search=&start_date=&end_date')
    }

    function my_properties() {
        set_current_tab("properties");
        history('/my_properties?my_property=true&property_type=sale&auction_type=online&status=all&page=1')
    }


    return (
        <div class="left_sidebar">
            <ul>
                <li class={current_tab == "dashboard" ? "active" : ""}><a onClick={dashboard}><i class="fa fa-tachometer" aria-hidden="true"></i><span>Dashboard</span></a></li>
                <li class={current_tab == "agents" ? "active" : ""}><a onClick={agents}><i class="fa fa-users" aria-hidden="true"></i><span>Agents</span></a></li>
                <li class={current_tab == "sellers" ? "active" : ""}><a onClick={sellers}><i class="fa fa-users" aria-hidden="true"></i><span>Sellers</span></a></li>
                <li class={current_tab == "customers" ? "active" : ""}><a onClick={customers}><i class="fa fa-users" aria-hidden="true"></i><span>Customers</span></a></li>
                <li class={current_tab == "properties" ? "active" : ""}><a onClick={properties}><i class="fa fa-building-o" aria-hidden="true"></i><span>Properties</span></a></li>
                <li class={current_tab == "my_properties" ? "active" : ""}><a onClick={my_properties}><i class="fa fa-building-o" aria-hidden="true"></i><span>My Properties</span></a></li>
                <li class={current_tab == "interests" ? "active" : ""}><a onClick={interest}><i class="fa fa-building-o" aria-hidden="true"></i><span>Interest Request Management</span></a></li>
                <li class={current_tab == "partner" ? "active" : ""}><a onClick={partner}><i class="fa fa-building-o" aria-hidden="true"></i><span>Partner</span></a></li>
                <li class={current_tab == "contact_us" ? "active" : ""}><a onClick={contact}><i class="fa fa-building-o" aria-hidden="true"></i><span>Contact Us</span></a></li>
                <li class={current_tab == "transaction_history" ? "active" : ""}><a onClick={transaction}><i class="fa fa-university" aria-hidden="true"></i><span>Transaction History</span></a></li>
            </ul>
        </div>
    );
};
export default SideMenu;