import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import GlobalContext from "../../context/GlobalContext";
import { userService } from "../../services/CommonServices";
import { Bar } from 'react-chartjs-2';
import { useNavigate } from "react-router";
const Dashboard = (props) => {
	var dashboardData = useContext(GlobalContext)
	const [chart, setChart] = useState({})
	let navigate = useNavigate()

	// const chart1=useRef({})
	const options = {
		scales: {
			yAxes: [
				{
					ticks: {
						beginAtZero: true,
					},
				},
			],
		},
		plugins: {

			legend: {
				display: false,
				// hidden:true 
				// onclick:(e)=>{
				// 	type.current('doughnut')
				// }
			}
		}
	};
	const [users_count, set_users_count] = useState([])
	useEffect(() => {

		user_count()

	}, []);

	function user_count() {
		dashboardData.set_loader(true)
		userService.users_count(dashboardData.token).then(response => {
			if (response.status == 200) {
				var dataResp = response.data
				set_users_count(response.data.data)
				var temp = {
					labels: ['Staff', 'Distributors', 'Jewellwers', 'Customers'],
					// data: [dataResp.customer_count, dataResp.distributor_count, dataResp.jeweller_count, dataResp.staff_count],
					datasets: [
						{
							label: 'Drishti Gems',
							data: [dataResp.staff_count, dataResp.distributor_count, dataResp.jeweller_count, dataResp.customer_count],
							backgroundColor: ['rgb(158, 188, 245)', 'rgb(130, 43, 43)', 'rgb(46, 68, 133)', 'rgb(12, 194, 170)']
						}
					]
				}
				setChart(temp)
				// chart1.current=temp
				// console.log(chart1)
				dashboardData.set_loader(false)
			}
		}).catch(err => {
			dashboardData.set_loader(false)
			if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                dashboardData.set_token('')
                dashboardData.set_is_logged_in("false")
                localStorage.clear()
                toast.info("Your session has been expired, please login again!..")
                navigate('/')
            }
		})
	}

	return (
		<div class="content_area">

			<div class="top3_area">
				<div class="row">
					<aside class="col-lg-4">
						<div class="white_three">
							<a onClick={()=>{navigate('/sellers?page=1')}}><span style={{ background: "#9ebcf5" }} ><i class="fa fa-users" aria-hidden="true"></i></span>
							<h3 style={{ color: "#f1a732" }} >{users_count.sellers}</h3>
							<p class="text-uppercase">Total Sellers </p>
							</a>
						</div>
					</aside>
					<aside class="col-lg-4">
						<div class="white_three">
						<a onClick={()=>{navigate('/agents?page=1')}}>
							<span style={{ background: "#822b2b" }} ><i class="fa fa-sitemap" aria-hidden="true"></i></span>
							<h3 style={{ color: "#2e4485" }}>{users_count.agents}</h3>
							<p class="text-uppercase">Total Agents</p>
							</a>
						</div>
					</aside>
					<aside class="col-lg-4">
						<div class="white_three">
						<a onClick={()=>{navigate('/customers?page=1')}}>
							<span style={{ background: "#2e4485" }} ><i class="fa fa-users" aria-hidden="true"></i></span>
							<h3 style={{ color: "#2e4485" }}>{users_count.customers}</h3>
							<p class="text-uppercase">Total Customers</p>
							</a>
						</div>
					</aside>
					<aside class="col-lg-4">
						<div class="white_three">
							<span style={{ background: "#0cc2aa" }}><i class="fa fa-handshake-o" aria-hidden="true"></i></span>
							<h3 style={{ color: "#0cc2aa" }} >{users_count.properties}</h3>
							<p class="text-uppercase">Total Approved Properties</p>
						</div>
					</aside>
					{/* <aside class="col-lg-4">
						<div class="white_three">
							<span style={{ background: "#0cc2aa" }}><i class="fa fa-handshake-o" aria-hidden="true"></i></span>
							<h3 style={{ color: "#0cc2aa" }} >{users_count.bids}</h3>
							<p class="text-uppercase">Total Bids</p>
						</div>
					</aside> */}

					{/* <aside class="col-lg-4">
						<div class="white_three">
							<span style={{ background: "#0cc2aa" }}><i class="fa fa-handshake-o" aria-hidden="true"></i></span>
							<h3 style={{ color: "#0cc2aa" }} >{users_count.seller_requests}</h3>
							<p class="text-uppercase">Total Seller Requests</p>
						</div>
					</aside> */}
					<aside class="col-lg-4">
						<div class="white_three">
							<span style={{ background: "#0cc2aa" }}><i class="fa fa-handshake-o" aria-hidden="true"></i></span>
							<h3 style={{ color: "#0cc2aa" }} >{users_count.property_requests}</h3>
							<p class="text-uppercase">Total Property Requests</p>
						</div>
					</aside>
					{/* <aside class="col-lg-4">
						<div class="white_three">
							<span style={{ background: "#0cc2aa" }}><i class="fa fa-handshake-o" aria-hidden="true"></i></span>
							<h3 style={{ color: "#0cc2aa" }} >{users_count.interest_requests}</h3>
							<p class="text-uppercase">Total Interest Requests</p>
						</div>
					</aside> */}
				</div>

			</div>

			<div class="row">
				{/* <aside class="col-lg-12">
					<div class="white_box total_sold">
						<h4>Drishti Gem's </h4>
						<div id="user_customer_operator" style={{ minWidth: "310px", margin: "0 auto" }}>
							<div className="row">
								<div className="col-md-12">
									<Bar data={chart} options={options} />
								</div>
							</div>

						</div>
					</div>
				</aside> */}
			</div>
		</div>
	);
};
export default Dashboard;