import { useContext } from "react";
import { Dropdown } from "react-bootstrap";


import GlobalContext from "../../../context/GlobalContext";
import logo from '../../../assets/images/logo.svg';
import { useNavigate } from "react-router";

const Header = (props) => {

	let history=useNavigate()
	var globalContext = useContext(GlobalContext)

	// function open_my_profile(event) {
	// 	event.preventDefault();
	// 	history('/profile')
	// }

	function open_edit_profile(event) {
		event.preventDefault();
		history('/edit_profile')
	}
	
	function logout(event) {
		event.preventDefault();
		sessionStorage.clear();
		globalContext.set_name('')
		globalContext.set_email('')
		globalContext.set_address('')
		globalContext.set_phone_number('')
		globalContext.set_password('')
		globalContext.set_image('')
		globalContext.set_is_logged_in("false")
		globalContext.set_token('')
		//globalContext.set_media_url('http://3.108.14.115:8000')
		history('/')
	} 

    return (
    <header class="header">
		<div class="dashboard_logoarea text-center">
			<a href="#" class="menu_bar"><i class="fa fa-bars" aria-hidden="true"></i></a>
			<a href="#"><img src={logo} alt="" /></a>
		</div>
		<div class="right_loggedarea">
		<Dropdown className="header_btn">
				{/* <li><a href="#">My Notifications <span class="notification_count">2</span></a></li> */}
					<Dropdown.Toggle id="dropdown-basic" class="dropdown d-flex">
						My Account
						<i class="fa fa-sort-desc ml-2" style={{marginLeft:"8px"}} aria-hidden="true"></i>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item href="" onClick={open_edit_profile} ><i class="fa fa-user" aria-hidden="true"></i>My Profile</Dropdown.Item>
					    {/*<Dropdown.Item href="" onClick={open_edit_profile} ><i class="fa fa-edit" aria-hidden="true"></i>Edit Profile</Dropdown.Item> */}
					    <Dropdown.Item href="" onClick={logout}><i class="fa fa-power-off" aria-hidden="true"></i>Logout</Dropdown.Item>
					</Dropdown.Menu>
				
			</Dropdown>
		</div>
	</header>
    );
};
export default Header;