import React, { useContext, useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import GlobalContext from "../../../context/GlobalContext";
import { userService } from "../../../services/CommonServices";

const AddPartner = (props) => {

  const [type, setType] = useState("attorney");
  var globalData = useContext(GlobalContext);
  let navigate = useNavigate();
  const [attImg, setAttImg] = useState({ img: "", file: "", error: "" });
  const [attLogo, setAttLogo] = useState({ img: "", file: "", error: "" });
  const [mortImg, setMortImg] = useState({ img: "", file: "", error: "" });
  const [mortLogo, setMortLogo] = useState({ img: "", file: "", error: "" });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm();

  const handleImageUrl = (e, type, img) => {
    if (e.target.files[0].type.split("/")[0] == "image") {
      if (type == "attorney") {
        if (img == "img") {
          setAttImg({ img: URL.createObjectURL(e.target.files[0]), file: e.target.files[0], error: "" })
        } else if (img == "logo") {
          setAttLogo({ img: URL.createObjectURL(e.target.files[0]), file: e.target.files[0], error: "" })
        }
      } else if (type == "mortgage") {
        if (img == "img") {
          setMortImg({ img: URL.createObjectURL(e.target.files[0]), file: e.target.files[0], error: "" })
        } else if (img == "logo") {
          setMortLogo({ img: URL.createObjectURL(e.target.files[0]), file: e.target.files[0], error: "" })
        }
      }
    } else {
      toast.error("Select a valid image.");
    }
  }

  const onAttorneySubmit = (data) => {
    if (!attImg.file) {
      document.getElementById("attorney-picture").focus({ preventScroll: false });
      setAttImg({
        ...attImg,
        error: "Please select image"
      })
      return false;
    } else {
      setAttImg({
        ...attImg,
        error: ""
      })
    }
    if (!attLogo.file) {
      document.getElementById("attorney-logo").focus({ preventScroll: false });
      setAttLogo({
        ...attLogo,
        error: "Please select logo"
      })
      return false;
    } else {
      setAttLogo({
        ...attLogo,
        error: ""
      })
    }
    globalData.set_loader(true);
    const payload = new FormData();
    payload.append("company_name", data.company_name)
    payload.append("email", data.email.toLowerCase())
    payload.append("office_number", data.office_number)
    payload.append("phone_number", data.phone_number)
    payload.append("link", data.link)
    payload.append("description", data.description)
    payload.append("image", attImg.file)
    payload.append("logo", attLogo.file)
    payload.append("name", data.name)
    payload.append("fax_number", data.fax_number)
    userService.addAttorney(payload, globalData.token).then(res => {
      globalData.set_loader(false);
      toast.success("Partner added successfully")
      setTimeout(() => {
        navigate("/partner?type=attorney&page=1");
      }, 500)
    }).catch((err) => {
      globalData.set_loader(false);
      if (err.response.status === 400) {
        toast.error(err.response.data.message);
      }
      if (err.response.status === 401) {
        globalData.set_token("");
        globalData.set_is_logged_in("false");
        localStorage.clear();
        toast.info("Your session has been expired, please login again!..");
        navigate("/");
      }
    });
  }

  const onMortgageSubmit = (data) => {
    if (!mortImg.file) {
      document.getElementById("attorney-picture").focus({ preventScroll: false });
      setMortImg({
        ...mortImg,
        error: "Please select image"
      })
    } else {
      setMortImg({
        ...mortImg,
        error: ""
      })
    }
    if (!mortLogo.file) {
      document.getElementById("attorney-logo").focus({ preventScroll: false });
      setMortLogo({
        ...mortLogo,
        error: "Please select logo"
      })
    } else {
      setMortLogo({
        ...mortLogo,
        error: ""
      })
    }
    globalData.set_loader(true);
    const payload = new FormData();
    payload.append("name", data.name)
    payload.append("company_name", data.company_name)
    payload.append("email", data.email.toLowerCase())
    payload.append("title", data.title)
    payload.append("nmls", data.nmls)
    payload.append("branch_nmls", data.branch_nmls)
    payload.append("link", data.link)
    payload.append("office_number", data.office_number)
    payload.append("phone_number", data.phone_number)
    payload.append("description", data.description)
    payload.append("image", mortImg.file)
    payload.append("logo", mortLogo.file)
    userService.addMortgage(payload, globalData.token).then(res => {
      globalData.set_loader(false);
      toast.success("Partner added successfully")
      setTimeout(() => {
        navigate("/partner?type=mortgage&page=1");
      }, 500)
    }).catch((err) => {
      globalData.set_loader(false);
      if (err.response.status === 400) {
        toast.error(err.response.data.message);
      }
      if (err.response.status === 401) {
        globalData.set_token("");
        globalData.set_is_logged_in("false");
        localStorage.clear();
        toast.info("Your session has been expired, please login again!..");
        navigate("/");
      }
    });
  }

  return (
    <>
      <section className="login-area forgot-area add-listing-page add_property partner-list-section">
        <div className="container">
          <div className="login-box-area">
            <div className="row justify-content-center">
              <aside className="col-md-11">
                <div className="login-box">
                  <div className="login-form">
                    <h2 className="text-center">Add Your Partner</h2>
                    <Tabs
                      defaultActiveKey="attorney"
                      transition={false}
                      id="listing-tab"
                      value={type}
                      className="mb-3 listing-tab-top"
                      onSelect={(key) => {
                        setType(key);
                      }}
                    >
                      <Tab eventKey="attorney" title="ATTORNEY">
                        <Form id="attorney" onSubmit={handleSubmit1(onAttorneySubmit)}>
                          <Row>
                            <Col md={6}>
                              <div className="my-3 text-center">
                                <div className="upload-img">
                                  <Form.Control
                                    type="file"
                                    onChange={(e) => handleImageUrl(e, "attorney", "img")}
                                  />

                                  <img
                                    id="attorney-picture"
                                    src={
                                      attImg.file ? attImg.img : require("../../../assets/images/download.png").default
                                    }
                                    className="upload-img"
                                    alt="img"
                                  ></img>
                                  <p>Upload picture here</p>
                                  {attImg.error && (
                                    <span style={{ color: "red" }}>
                                      {attImg.error}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3 text-center">
                                <div className="upload-img">
                                  <Form.Control
                                    type="file"
                                    onChange={(e) => handleImageUrl(e, "attorney", "logo")}
                                  />
                                  <img
                                    id="attorney-logo"
                                    src={
                                      attLogo.file ? attLogo.img : require("../../../assets/images/download.png").default
                                    }
                                    className="upload-img"
                                    alt="img"
                                  ></img>
                                  <p>Upload logo here</p>
                                  {attLogo.error && (
                                    <span style={{ color: "red" }}>
                                      {attLogo.error}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Company Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  maxLength={30}
                                  {...register1("company_name", {
                                    required: "Company Name is required.",
                                  })}
                                  placeholder="Enter Company Name"
                                />
                                {errors1.company_name && (
                                  <span style={{ color: "red" }}>
                                    {errors1.company_name.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Full Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register1("name", {
                                    required:
                                      "Name is required.",
                                  })}
                                  placeholder="Enter name"
                                />
                                {errors1.name && (
                                  <span style={{ color: "red" }}>
                                    {errors1.name.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register1("email", {
                                    required: "Email is required.",
                                    pattern: {
                                      value: /^[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/,
                                      message:
                                        "Enter valid email address.",
                                    },
                                  })}
                                  placeholder="Enter email"
                                />
                                {errors1.email && (
                                  <span style={{ color: "red" }}>
                                    {errors1.email.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Office Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register1("office_number", {
                                    required: "Office number is required.",
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                  placeholder="Enter office number"
                                />
                                {errors1.office_number && (
                                  <span style={{ color: "red" }}>
                                    {errors1.office_number.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Fax Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register1("fax_number", {
                                    required: "Fax number is required.",
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                  placeholder="Enter fax number"
                                />
                                {errors1.fax_number && (
                                  <span style={{ color: "red" }}>
                                    {errors1.fax_number.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Phone Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register1("phone_number", {
                                    required: "Phone number is required.",
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                  placeholder="Enter phone number"
                                />
                                {errors1.phone_number && (
                                  <span style={{ color: "red" }}>
                                    {errors1.phone_number.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Website Link</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={100}
                                  id=""
                                  {...register1("link", {
                                    required: "Website link is required.",
                                    pattern: {
                                      value: /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
                                      message:
                                        "Only valid url are allowed.",
                                    },
                                  })}
                                  placeholder="Enter website link"
                                />
                                {errors1.link && (
                                  <span style={{ color: "red" }}>
                                    {errors1.link.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="my-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    placeholder="Enter description"
                                    rows={3}
                                    {...register1("description", {
                                      required: "Description is required.",
                                    })}
                                  />
                                </Form.Group>
                                {errors1.description && (
                                  <>
                                    <span style={{ color: "red" }}>
                                      {errors1.description.message}
                                    </span>
                                    <br />
                                  </>
                                )}
                              </div>
                              <button
                                type="submit"
                                className="btn sign-btn fill-btn  text-white listing-submit"
                              >
                                Submit
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      </Tab>
                      <Tab eventKey="mortgage" title="MORTGAGE">
                        <Form id="mortgage" onSubmit={handleSubmit(onMortgageSubmit)}>
                          <Row>
                            <Col md={6}>
                              <div className="my-3 text-center">
                                <div className="upload-img">
                                  <Form.Control
                                    type="file"
                                    onChange={(e) => handleImageUrl(e, "mortgage", "img")}
                                  />

                                  <img
                                    id="mortgage-picture"
                                    src={
                                      mortImg.file ? mortImg.img : require("../../../assets/images/download.png").default
                                    }
                                    className="upload-img"
                                    alt="img"
                                  ></img>
                                  <p>Upload picture here</p>
                                  {mortImg.error && (
                                    <span style={{ color: "red" }}>
                                      {mortImg.error}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3 text-center">
                                <div className="upload-img">
                                  <Form.Control
                                    type="file"
                                    onChange={(e) => handleImageUrl(e, "mortgage", "logo")}
                                  />

                                  <img
                                    id="mortgage-logo"
                                    src={
                                      mortLogo.file ? mortLogo.img : require("../../../assets/images/download.png").default
                                    }
                                    className="upload-img"
                                    alt="img"
                                  ></img>
                                  <p>Upload logo here</p>
                                  {mortLogo.error && (
                                    <span style={{ color: "red" }}>
                                      {mortLogo.error}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Company Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  maxLength={30}
                                  {...register("company_name", {
                                    required: "Company name is required.",
                                  })}
                                  placeholder="Enter company name"
                                />
                                {errors.company_name && (
                                  <span style={{ color: "red" }}>
                                    {errors.company_name.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Full Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register("name", {
                                    required:
                                      "Name is required.",
                                  })}
                                  placeholder="Enter name"
                                />
                                {errors.name && (
                                  <span style={{ color: "red" }}>
                                    {errors.name.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Title
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  maxLength={30}
                                  {...register("title", {
                                    required: "Title is required.",
                                  })}
                                  placeholder="Enter title"
                                />
                                {errors.title && (
                                  <span style={{ color: "red" }}>
                                    {errors.title.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register("email", {
                                    required: "Email is required.",
                                    pattern: {
                                      value: /^[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/,
                                      message:
                                        "Enter valid email address.",
                                    },
                                  })}
                                  placeholder="Enter email"
                                />
                                {errors.email && (
                                  <span style={{ color: "red" }}>
                                    {errors.email.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Office Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register("office_number", {
                                    required: "Office number is required.",
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                  placeholder="Enter office number"
                                />
                                {errors.office_number && (
                                  <span style={{ color: "red" }}>
                                    {errors.office_number.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Phone Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register("phone_number", {
                                    required: "Phone number is required.",
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                  placeholder="Enter phone number"
                                />
                                {errors.phone_number && (
                                  <span style={{ color: "red" }}>
                                    {errors.phone_number.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">NMLS</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={100}
                                  id=""
                                  {...register("nmls", {
                                    required: "NMLS is required.",
                                  })}
                                  placeholder="Enter nmls"
                                />
                                {errors.nmls && (
                                  <span style={{ color: "red" }}>
                                    {errors.nmls.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Branch NMLS</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={50}
                                  id=""
                                  {...register("branch_nmls", {
                                    required: "Branch NMLS is required.",
                                  })}
                                  placeholder="Enter branch nmls"
                                />
                                {errors.branch_nmls && (
                                  <span style={{ color: "red" }}>
                                    {errors.branch_nmls.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Website Link</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={100}
                                  id=""
                                  {...register("link", {
                                    required: "Website link is required.",
                                    pattern: {
                                      value: /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
                                      message:
                                        "Only valid url are allowed.",
                                    },
                                  })}
                                  placeholder="Enter website link"
                                />
                                {errors.link && (
                                  <span style={{ color: "red" }}>
                                    {errors.link.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="my-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter description"
                                    {...register("description", {
                                      required: "Description is required.",
                                    })}
                                  />
                                </Form.Group>
                                {errors.description && (
                                  <>
                                    <span style={{ color: "red" }}>
                                      {errors.description.message}
                                    </span>
                                    <br />
                                  </>
                                )}
                              </div>
                              <button
                                type="submit"
                                className="btn sign-btn fill-btn  text-white listing-submit"
                              >
                                Submit
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddPartner;
