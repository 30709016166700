import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useHistory } from "react-router-dom";
import { userService } from "../../../services/CommonServices";
import Pagination from 'react-js-pagination';
import Swal from "sweetalert2";
import GlobalContext from "../../../context/GlobalContext";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import moment from "moment";

const MyPropertiesList = (props) => {
    var global_data = useContext(GlobalContext)
    let history = useNavigate()
    let query = new URLSearchParams(window.location.search)
    const [properties_list, set_properties_list] = useState([])
    const [page, set_page] = useState(query.get('page'))
    const [total_count, set_total_count] = useState(0)

    const [search, set_search] = useState('')
    const [propertyType, setPropertyType] = useState('');
    const [status, setStatus] = useState("");
    const [my_property, set_my_property] = useState("");
    const [auction_type, set_auction_type]= useState("")

    useEffect(() => {
        var temp_page = query.get('page');
        set_page(Number(temp_page));
        if(query.get('search') != null) {
            set_search(query.get('search'));
        } else {
            set_search("");
        }
        
        setPropertyType(query.get("property_type"));
        setStatus(query.get("status"));
        set_my_property(query.get("my_property"))
        set_auction_type(query.get("auction_type"))
        get_properties_list(query.get("my_property"),query.get("property_type"),query.get("auction_type"),query.get("status"),temp_page, query.get('search') != null ? query.get('search') : search);
    }, [page, global_data.stat, propertyType, status, my_property, auction_type])

    function search_data(event) {
        if (page == 1) {
            get_properties_list(my_property,propertyType, auction_type, status, page, search);
            handle_page_change(1);
        } else {
            set_page(1);
            handle_page_change(1);
        }
    }


    function get_properties_list(my_property, propertyType,auction_type, status, page, search) {
        global_data.set_loader(true)
        userService.get_properties_list(global_data.token,my_property,propertyType,auction_type, status, page, search).then(response => {
            if (response.status == 200) {
                set_properties_list(response.data?.data)
                set_total_count(response.data.totalItems)
                global_data.set_loader(false)
            }
        }).catch(err => {
            global_data.set_loader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                global_data.set_token('')
                global_data.set_is_logged_in("false")
                localStorage.clear()
                toast.info("Your session has been expired, please login again!.")
                history('/')
            }
        })
    }

    function add_property() {
        history('/add_property')
    }

    function add_listing() {
        history('/add_listing')
    }

    function view_property(event, id) {
        event.preventDefault();
        history('/view_property' + id)
    }

    function property_status() {
        if (my_property =="false"){
            set_my_property("true")
            history("/my_properties?my_property=true&property_type=sale&status=all&page=1")
        }
        else{
            set_my_property("false")
            history("/my_properties?my_property=false&property_type=sale&status=all&page=1")
        }
    }


    function approve_property(event, id, status) {
        event.preventDefault();
        Swal.fire({
            title: "Do you want to approve this property?",
            icon: "warning",
            focusConfirm: false,
            showDenyButton: true,
            denyButtonText: "Cancel",
            confirmButtonText: "Approve"
        }).then((result) => {
            if (result.isConfirmed) {
                global_data.set_loader(true);
                var formData = {
                }
                userService.accept_property(global_data.token, id, formData).then((response) => {
                    global_data.set_loader(false);
                    if (response.status == 200) {
                        Swal.fire(
                            status == 'Approved',
                            "Property approved successfully.",
                            "success"

                        )
                        var tempData = [...properties_list];
                        for(var i = 0; i < tempData.length; i++) {
                            if(tempData[i].id == id) {
                                tempData[i].is_approved = !tempData[i].is_approved;
                            }
                        }
                        set_properties_list(tempData);
                    }
                })
            }
            else if (result.isDenied) {
                Swal.close()
            }
        }
        )
    }

    function handle_page_change(event) {
        set_page(event)
        history(`/my_properties?my_property=${my_property}&property_type=${propertyType}&auction_type=${auction_type}&status=${status}&page=${event}&search=${search}`)
    }

    function handlePropertyChange(event){
        history(`/my_properties?my_property=${my_property}&property_type=${event.target.value}&auction_type=${auction_type}&status=${status}&page=1`)
        setPropertyType(event.target.value)
    }

    function handleStatusChange(event){
        history(`/my_properties?my_property=${my_property}&property_type=${propertyType}&auction_type=${auction_type}&status=${event.target.value}&page=1`)
        setStatus(event.target.value)
    }

    function handleAuctionChange(event){
        history(`/my_properties?my_property=${my_property}&property_type=${propertyType}&auction_type=${event.target.value}&status=${status}&page=1`)
        set_auction_type(event.target.value)
    }

    function viewDetail (id){
        history(`/bid_detail?id=${id}`)
    }
    return (

        <div class="content_area">

            <div class="tables_area">
                <div className="top-listing-header">
                <h2 class="pull-left" style={{marginRight:"auto"}}>My Properties</h2>
               
                {/* <a onClick={add_listing} class="blue_btn yellow_btn pull-right text-uppercase">Add New Listing</a> */}
                {/* <p onClick={property_status} class={my_property =="false"?"my_property_btn pull-right text-uppercase":"blue_btn pull-right text-uppercase"}>My Properties</p> */}
                <Form.Select className="pull-right" size="lg" onChange={handlePropertyChange}>
                    <option value="sale" selected={propertyType=="sale"}>Featured</option>
                    <option value="auction" selected={propertyType=="auction"}>Auction</option>
                </Form.Select>
                
                {my_property =="false"?<Form.Select className="pull-right" onChange={handleStatusChange}>
                <option value="all" selected={status=="all"}>All</option>
                    <option value="listed" selected={status=="listed"}>Listed</option>
                    <option value="under_review" selected={status=="under_review"}>Under Review</option>
                </Form.Select>:""}
                {propertyType =="auction"?<Form.Select className="pull-right" onChange={handleAuctionChange}>
                <option value="online" selected={auction_type=="online"}>Online</option>
                    <option value="in_person" selected={auction_type=="in_person"}>In Person</option>
                </Form.Select>:""}
                <div class="pull-right searchbtn_btn">
                    <input type="text" placeholder="Search By Property Name" value={search} onChange={(event) => { set_search(event.target.value) }} />
                    <span>
                        <i class="fa fa-search" onClick={search_data}></i>
                    </span>
                </div>
                <a onClick={add_property} class="blue_btn pull-right text-uppercase">Add New Property</a>
                </div>
                <div class="clear"></div>
                {properties_list.length == 0 ? <h3 className="text-center" style={{ marginTop: "40px" }}>No Data Found</h3> :
                    <div class="white_box">
                        <div class="table-responsive">

                            <table width="100%" cellspacing="0" cellpadding="0">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Property Name</th>
                                        <th>Address</th>
                                        {/* <th>Role</th> */}
                                        <th>Added By</th>
                                        {propertyType =="auction"?<th>Auction Status</th>:""}
                                        <th>ACTION</th>
                                        {propertyType=="auction"  && auction_type=="online"?<th>BIDS</th>:""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {properties_list.length > 0 && properties_list?.map((item, index) =>
                                        <tr>
                                            <td> {(20 * (page - 1)) + index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.address}</td>
                                            <td>{item.added_by?.name + '(' + item.added_by?.email + ')'}</td>
                                            {propertyType=="auction"?<td>{item.auction_type=="in_person"?moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")> moment(item.show_start_time).format("YYYY-MM-DDTHH:mm:ss")?"Live":"Started soon":moment(new Date()).format("YYYY-MM-DDTHH:mm")<moment(item.show_start_time).format("YYYY-MM-DDTHH:mm:ss")?"Started soon":(moment(new Date()).format("YYYY-MM-DDTHH:mm")>moment(item.show_start_time).format("YYYY-MM-DDTHH:mm:ss") &&moment(new Date()).format("YYYY-MM-DDTHH:mm")<moment(item.show_end_time).format("YYYY-MM-DDTHH:mm:ss"))?"Live":moment(new Date()).format("YYYY-MM-DDTHH:mm")>moment(item.show_end_time).format("YYYY-MM-DDTHH:mm:ss")?"Expired":""}</td>:""}
                                            <td>
                                               <div className="flex_row">
                                                <button className="approvedd" data-toggle="tooltip" data-placement="top" title="View"  onClick={(event) => { history(`/edit_property/?id=${item.id}`,{state:propertyType=="auction"?item.bid.length:0} ) }} >View</button>
                                               </div>
                                            </td>
                                            {propertyType=="auction"  && auction_type=="online"? item.bid.length >0 ?<td><a className="text_size" onClick={()=>{viewDetail(item.id)}}>{item.bid.length}</a></td>:<td><a className="text_size">{item.bid.length}</a></td>:""}
                                        </tr>
                                    )}


                                </tbody>
                            </table>

                        </div>

                    </div>
                }
            </div>
                {properties_list.length != 0 &&
                    <div class="paginationrow">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={20}
                            totalItemsCount={total_count}
                            pageRangeDisplayed={5}
                            prevPageText="Previous"
                            nextPageText="Next"
                            onChange={(event) => { handle_page_change(event); }}
                        />

                    </div>}

        </div>
    );
};
export default MyPropertiesList;