import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useHistory } from "react-router-dom";
import { userService } from "../../../services/CommonServices";
import Pagination from 'react-js-pagination';
import Swal from "sweetalert2";
import GlobalContext from "../../../context/GlobalContext";
import { toast } from "react-toastify";

const CustomersList = (props) => {
    var global_data = useContext(GlobalContext)
    let history = useNavigate()
    const [customers_list, set_customers_list] = useState([])
    const [page, set_page] = useState(1)
    const [total_count, set_total_count] = useState(0)

    const [search, set_search] = useState('')

    let query = new URLSearchParams(window.location.search)

    useEffect(() => {
        var temp_page = query.get('page');
        set_page(Number(temp_page));
        if(query.get('search') != null) {
            set_search(query.get('search'));
        } else {
            set_search("");
        }
        get_customers_list(temp_page, query.get('search') != null ? query.get('search') : search);
    }, [page, global_data.stat])

    function search_data(event) {
        if (page == 1) {
            get_customers_list(page, search);
            handle_page_change(1);
        } else {
            set_page(1);
            handle_page_change(1);
        }
    }


    function get_customers_list(page, search) {
        global_data.set_loader(true)
        userService.get_customers_list(global_data.token, page, search).then(response => {
            if (response.status == 200) {
                set_customers_list(response.data.data)
                set_total_count(response.data.totalItems)
                global_data.set_loader(false)
            }
        }).catch(err => {
            global_data.set_loader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                global_data.set_token('')
                global_data.set_is_logged_in("false")
                localStorage.clear()
                toast.info("Your session has been expired, please login again!..")
                history('/')
            }
        })
    }

    function add_customer() {
        history('/add_customer')
    }

    function view_customer(event, id) {
        event.preventDefault();
        history('/view_customer' + id)
    }

    function edit_customer(event, id) {
        event.preventDefault();
        history('/edit_customer?id=' + id)
    }

    function delete_customer(event, id, status) {
        event.preventDefault();
        Swal.fire({
            title: "Do you want to " + (status == false ? "activate" : "suspend") + " this customer?",
            icon: "warning",
            focusConfirm: false,
            showDenyButton: true,
            denyButtonText: "Cancel",
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.isConfirmed) {
                global_data.set_loader(true);
                var formData = {
                    "is_suspend" : status
                }
                userService.delete_user(global_data.token, id, formData).then((response) => {
                    global_data.set_loader(false);
                    if (response.status == 200) {
                        Swal.fire(
                            status == false ? 'Activated' : 'Suspended',
                            "Customer " + (status == false ? "activated" : "suspended") +" successfully.",
                            "success"

                        )
                        var tempData = [...customers_list];
                        for(var i = 0; i < tempData.length; i++) {
                            if(tempData[i].id == id) {
                                tempData[i].is_suspend = !tempData[i].is_suspend;
                            }
                        }
                        set_customers_list(tempData);
                    }
                })
            }
            else if (result.isDenied) {
                Swal.close()
            }
        }
        )
    }

    function handle_page_change(event) {
        set_page(event)
        if(search == '') {
            history("/customers?page=" + event)
        } else {
            history("/customers?page=" + event + "&search=" + search)
        }
    }
    return (

        <div class="content_area">

            <div class="tables_area">
                <h2 class="pull-left">Customers Listing</h2>

                {/* <a onClick={add_customer} class="blue_btn yellow_btn pull-right text-uppercase">Add New Customer</a> */}
                <div class="pull-right searchbtn_btn">
                    <input type="text" placeholder="Search By Name or Email" value={search} onChange={(event) => { set_search(event.target.value) }} />
                    <span>
                        <i class="fa fa-search" onClick={search_data}></i>
                    </span>
                </div>
                <div class="clear"></div>
                {customers_list.length == 0 ? <h3 className="text-center" style={{ marginTop: "40px" }}>No Data Found</h3> :
                    <div class="white_box">
                        <div class="table-responsive">

                            <table width="100%" cellspacing="0" cellpadding="0">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customers_list.length > 0 && customers_list.map((item, index) =>
                                        <tr>
                                            <td> {(20 * (page - 1)) + index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>
                                            {item.is_suspend == true?
                                                    <button data-toggle="tooltip" data-placement="top" title="Activate" href="" onClick={(event) => { delete_customer(event, item.id, false) }} class="approvedd">Activate</button>
                                                :
                                                    <button data-toggle="tooltip" data-placement="top" title="Suspend" href="" onClick={(event) => { delete_customer(event, item.id, true) }} class="approvedd">Suspend</button>
                                                }
                                                </td>
                                        </tr>
                                    )}


                                </tbody>
                            </table>

                        </div>

                    </div>
                }
            </div>
                {customers_list.length != 0 &&
                    <div class="paginationrow">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={20}
                            totalItemsCount={total_count}
                            pageRangeDisplayed={5}
                            prevPageText="Previous"
                            nextPageText="Next"
                            onChange={(event) => { handle_page_change(event); }}
                        />

                    </div>}

        </div>
    );
};
export default CustomersList;