import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/CommonServices";
import GlobalContext from "../../../context/GlobalContext";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from "moment";


const EditProfile = () => {
    let navigate = useNavigate()
    let globalData = useContext(GlobalContext)
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [imgErr, setImgErr] = useState("")
    const { register, handleSubmit, watch, formState: { errors }, setValue, control, getValues, setError } = useForm();
    const media_url = "https://api.realzon.net"
    const onSubmit = data => {
        
        if (imageUrl == "") {
            document.getElementById('sale22').focus({ preventScroll: false })
            setImgErr('please upload images.')
            return false
        }
        globalData.set_loader(true)
        let payload = new FormData()
        payload.append("name", data.name);
        payload.append('image', image)
        userService.update_profile(globalData.token, globalData.id, payload).then(response => {
            if (response.status === 200) {
              
                toast.success("Profile updated successfully.")
                globalData.set_loader(false)
            }
        }).catch(err => {
            globalData.set_loader(false)
            toast.error(err.response.data.message)
        })

    }

    const GetUserDetail = () => {
        globalData.set_loader(true)
        userService.get_user(globalData.id, globalData.token).then(response => {
            if (response.status === 200) {
                globalData.set_loader(false)
                setValue('name', response.data.data.name)
                setValue('email', response.data.data.email)
                setValue("image", response.data.image)
                setImageUrl(media_url+response.data.data.image)
            }
        }).catch(err => {
            globalData.set_loader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.respoonse.status === 401) {
                globalData.setToken('')
                localStorage.clear()
                toast.info("Your session has been expired, please login again!..")
                navigate('/')
            }
        })
    }

    const handleImageUrl = (e) => {
        if (e.target.files.length > 0) {
            for (var i = 0; i < e.target.files.length; i++) {
                setImage(e.target.files[0])
                setImageUrl(URL.createObjectURL(e.target.files[0]))
            }
        }
    }

    useEffect(() => {
        console.log('add')
        GetUserDetail()
    }, [globalData.token])

    return (
        <>
            <section className="login-area forgot-area add-listing-page add_property">
                <div className="container">
                    <div className="login-box-area">
                        <div className="row justify-content-center">
                            <aside className="col-md-11">
                                <div className="login-box">
                                    <div className="login-form">
                                        <h2 className="text-center">Edit Profile</h2>

                                        <form id="sale" onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="my-3 text-center">
                                                        <div className="upload-img">
                                                            <Form.Control type="file" onChange={(e) => handleImageUrl(e)} onClick={(e) => e.target.value = ''} />

                                                            <img
                                                                id="sale22"
                                                                src={imageUrl!==''?imageUrl: require('../../../assets/images/download.png').default}
                                                                className="upload-img image-uploded"

                                                            ></img>
                                                            <p>Upload profile image</p>
                                                        </div>
                                                        {imgErr && <span style={{ color: "red" }}>{imgErr}</span>}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="my-3">
                                                        <label className="form-label">
                                                            Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id=""
                                                            maxLength={30}
                                                            {...register("name", { required: "Name is required.", })}
                                                            placeholder="Enter Name"
                                                        />
                                                        {errors.name && <span style={{ color: "red" }}>{errors.name.message}</span>}
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="my-3">
                                                        <label className="form-label">
                                                           Email
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id=""
                                                            maxLength={30}
                                                            {...register("email", { required: "Name is required.", })}
                                                            placeholder="Enter Email"
                                                            disabled={true}
                                                        />
                                                       
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                   
                                                    <button className="btn sign-btn fill-btn  text-white listing-submit">
                                                        Update
                                                    </button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default EditProfile;