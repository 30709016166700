import axios from "axios";


// const base_url = 'http://192.168.2.67:8000/api/'
// const base_url = 'https://apirealzon.ecomempire.in'
const base_url = 'https://api.realzon.net'
// const base_url = 'https://admin.realzon.net'


export const userService = {
    base_url,
    login,
    forgot_password,
    resend_otp,
    verify_otp,
    users_count,
    add_user,
    get_agents_list,
    get_sellers_list,
    get_customers_list,
    get_properties_list,
    get_states_districts_cities_list,
    get_states_list,
    get_district_list_by_state,
    get_city_list_by_state,
    get_city_list_by_district,
    get_user_by_id,
    delete_user,
    accept_user,
    update_user,
    update_profile,
    change_password,
    accept_property,
    add_role,
    get_properties_detail,
    get_data_for_gem,
    get_contact_us_list,
    get_contact_us_by_id,

    get_user,
    interest_details,
    get_orders_list,
    get_orders_status,
    update_order_status,
    get_order_details_by_id,
    add_property,
    update_property,
    get_property,
    delete_image,
    property_interest,
    get_user_properties_list,
    get_transaction_history,
    mortgage,
    attorney,
    addMortgage,
    addAttorney,
    updateAttorneyStatus,
    updateMortgageStatus,
}

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function add_property(payload, token) {
    return axios.post(base_url + '/api/add_property/', payload, {
        headers: {
            "timezone": timezone,
            "Authorization": "Token " + token
        }
    })
}

function update_property(payload, id, token) {
    return axios.put(base_url + `/api/property/${id}/`, payload, {
        headers: {
            "timezone": timezone,
            "Authorization": "Token " + token
        }
    })
}

function get_property(id, token) {
    return axios.get(base_url + `/api/property/${id}/`, {
        headers: {
            "Authorization": "Token " + token
        }
    })
}

function delete_image(payload, token) {
    return axios.post(base_url + `/api/remove_image/`, payload, {
        headers: {
            "Authorization": "Token " + token
        }
    })
}



function login(payload) {
    return axios.post(base_url + '/api/admin_login/', payload);
}
function forgot_password(payload) {
    return axios.post(base_url + '/api/send_verify_link/', payload);
}

function resend_otp(payload) {
    return axios.post(base_url + '/api/admin_resendOtp/', payload);
}

function verify_otp(payload) {
    return axios.post(base_url + '/api/verify_otp/', payload);
}

function add_user(payload) {
    return axios.post(base_url + '/api/users/', payload);
}

function get_agents_list(token, page, search) {
    if (search != "") {
        return axios.get(base_url + '/api/admin_agent_list/?page=' + page + '&search=' + search, {
            headers: {
                Authorization: 'Token ' + token,
                Accept: 'application/json',
            }
        });
    } else {
        return axios.get(base_url + '/api/admin_agent_list/?page=' + page, {
            headers: {
                Authorization: 'Token ' + token,
                Accept: 'application/json',
            }
        });
    }
}

function get_sellers_list(token, page, search) {
    if (search != "") {
        return axios.get(base_url + '/api/admin_seller_list/?page=' + page + '&search=' + search, {
            headers: {
                Authorization: 'Token ' + token,
                Accept: 'application/json',
            }
        });
    } else {
        return axios.get(base_url + '/api/admin_seller_list/?page=' + page, {
            headers: {
                Authorization: 'Token ' + token,
                Accept: 'application/json',
            }
        });
    }
}

function get_customers_list(token, page, search) {
    if (search != "") {
        return axios.get(base_url + '/api/admin_customer_list/?page=' + page + '&search=' + search, {
            headers: {
                Authorization: 'Token ' + token,
                Accept: 'application/json',
            }
        });
    } else {
        return axios.get(base_url + '/api/admin_customer_list/?page=' + page, {
            headers: {
                Authorization: 'Token ' + token,
                Accept: 'application/json',
            }
        });
    }
}

function get_properties_list(token, my_property, type, auction_type, status, page, search) {
    return axios.get(base_url + `/api/admin_property_list/?my_property=${my_property}&type=${type}&auction_type=${auction_type}&status=${status}&page=${page}&search=${search}`, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        }
    });
}

function get_user_properties_list(token, id, type, status, page, search) {
    return axios.get(base_url + `/api/user_property/${id}/?type=${type}&status=${status}&page=${page}&search=${search}`, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        }
    });
}
function get_properties_detail(token, id) {
    return axios.get(base_url + `/api/admin_property_detail/${id}/`, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        }
    });
}

function property_interest(token, type, page, search) {
    return axios.get(base_url + `/api/interest_list/?property_type=${type}&page=${page}&search=${search}`, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        }
    });
}

function interest_details(token, id) {
    return axios.get(base_url + `/api/view_interest/${id}/`, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        }
    });
}

function get_user(id, token) {
    return axios.get(base_url + `/api/users/${id}`, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        },
    })
}

function get_transaction_history(token, transactionType, page, start_date, end_date, search) {
    return axios.get(base_url + `/api/transaction_history/?transactionType=${transactionType}&page=${page}&start_date=${start_date}&end_date=${end_date}&search=${search}`, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        },
    })
}

function update_profile(token, id, payload) {
    return axios.put(base_url + `/api/users/${id}`, payload, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        },
    });
}

function get_contact_us_list(token, page) {
    return axios.get(base_url + `/api/contact_us_list/?page=${page}`, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        },
    })
}

function get_user_by_id(id) {
    return axios.get(base_url + '/users/' + id);
}

function get_states_districts_cities_list() {
    return axios.get(base_url + '/get_states_districts_cities_list/');
}

function get_states_list() {
    return axios.get(base_url + '/states/');
}
function get_district_list_by_state(state_id) {
    return axios.get(base_url + '/get_district_list_by_state/' + state_id);
}
function get_city_list_by_state(state_id) {
    return axios.get(base_url + '/get_city_list_by_state/' + state_id);
}
function get_city_list_by_district(district_id) {
    return axios.get(base_url + '/get_city_list_by_district/' + district_id);
}



function delete_user(token, id, payload) {
    return axios.post(base_url + '/api/suspend_user/' + id + '/', payload, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        },
    });
}

function accept_user(token, id, payload) {
    return axios.post(base_url + '/api/accept_user/' + id + '/', payload, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        },
    });
}

function accept_property(token, id, payload) {
    return axios.post(base_url + '/api/accept_property/' + id + '/', payload, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        },
    });
}

function update_user(id, payload) {
    return axios.put(base_url + '/api/users/' + id, payload);
}

function add_role(payload) {
    return axios.post(base_url + '/groups/', payload);
}


function get_data_for_gem() {
    return axios.get(base_url + '/get_data_for_gem/');
}



function get_contact_us_by_id(id) {
    return axios.get(base_url + '/contact_us/' + id)
}

function change_password(token, data) {
    return axios.post(base_url + '/change_password/', data, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        },
    })
}


function users_count(token) {
    return axios.get(base_url + '/api/admin_dashboard/', {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        }
    })
}

function get_orders_list(token, page) {
    return axios.get(base_url + '/orders_list/', {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        },
    }
    )
}
function get_orders_status() {
    return axios.get(base_url + '/order_status/')
}
function update_order_status(token, id, payload) {

    return axios.put(base_url + '/user_orders/' + id, payload,
        {
            headers: {
                Authorization: 'Token ' + token,
                Accept: 'application/json',
            },
        }
    )
}

function get_order_details_by_id(id) {
    return axios.get(base_url + '/user_orders/' + id)
}

function mortgage(page, token) {
    return axios.get(`${base_url}/api/mortgage/?page=${page}`, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        }
    })
}

function attorney(page, token) {
    return axios.get(`${base_url}/api/attorney/?page=${page}`, {
        headers: {
            Authorization: 'Token ' + token,
            Accept: 'application/json',
        }
    })
}

function addMortgage(params, token) {
    return axios.post(`${base_url}/api/mortgage/`, params, {
        headers: {
            "timezone": timezone,
            "Authorization": "Token " + token
        }
    })
}

function addAttorney(params, token) {
    return axios.post(`${base_url}/api/attorney/`, params, {
        headers: {
            "timezone": timezone,
            "Authorization": "Token " + token
        }
    })
}

function updateMortgageStatus(params, token) {
    return axios.put(`${base_url}/api/mortgage/`, params, {
        headers: {
            "timezone": timezone,
            "Authorization": "Token " + token
        }
    })
}

function updateAttorneyStatus(params, token) {
    return axios.put(`${base_url}/api/attorney/`, params, {
        headers: {
            "timezone": timezone,
            "Authorization": "Token " + token
        }
    })
}
