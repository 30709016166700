import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useHistory } from "react-router-dom";
import { userService } from "../../../services/CommonServices";
import Pagination from 'react-js-pagination';
import Swal from "sweetalert2";
import GlobalContext from "../../../context/GlobalContext";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

const AgentsList = (props) => {
    var global_data = useContext(GlobalContext)
    let history = useNavigate()
    const [agents_list, set_agents_list] = useState([])
    const [page, set_page] = useState(1)
    const [total_count, set_total_count] = useState(0)
    const [search, set_search] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [image, setImage] = useState('')
    const media_url = "https://api.realzon.net"
    let query = new URLSearchParams(window.location.search)

    useEffect(() => {
        var temp_page = query.get('page');
        set_page(Number(temp_page));
        if (query.get('search') != null) {
            set_search(query.get('search'));
        } else {
            set_search("");
        }
        get_agents_list(temp_page, query.get('search') != null ? query.get('search') : search);
    }, [page, global_data.stat])

    function search_data(event) {
        if (page == 1) {
            get_agents_list(page, search);
            handle_page_change(1);
        } else {
            set_page(1);
            handle_page_change(1);
        }
    }

    const handleShow = (image) => {
        setShow(true)
        setImage(media_url + image)
    }


    function get_agents_list(page, search) {
        global_data.set_loader(true)
        userService.get_agents_list(global_data.token, page, search).then(response => {
            if (response.status == 200) {
                set_agents_list(response.data.data)
                set_total_count(response.data.totalItems)
                global_data.set_loader(false)
            }
        }).catch(err => {
            global_data.set_loader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                global_data.set_token('')
                global_data.set_is_logged_in("false")
                localStorage.clear()
                toast.info("Your session has been expired, please login again!..")
                history('/')
            }
        })
    }

    function add_agent() {
        history('/add_agent')
    }

    function view_agent(event, id) {
        event.preventDefault();
        history('/view_agent' + id)
    }

    function edit_agent(event, id) {
        event.preventDefault();
        history('/edit_agent?id=' + id)
    }

    function delete_agent(event, id, status) {
        event.preventDefault();
        Swal.fire({
            title: "Do you want to " + (status == false ? "activate" : "suspend") + " this agent?",
            icon: "warning",
            focusConfirm: false,
            showDenyButton: true,
            denyButtonText: "Cancel",
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.isConfirmed) {
                global_data.set_loader(true);
                var formData = {
                    "is_suspend": status
                }
                userService.delete_user(global_data.token, id, formData).then((response) => {
                    global_data.set_loader(false);
                    if (response.status == 200) {
                        Swal.fire(
                            status == false ? 'Activated' : 'Suspended',
                            "Agent " + (status == false ? "activated" : "suspended") + " successfully.",
                            "success"

                        )
                        var tempData = [...agents_list];
                        for (var i = 0; i < tempData.length; i++) {
                            if (tempData[i].id == id) {
                                tempData[i].is_suspend = !tempData[i].is_suspend;
                            }
                        }
                        set_agents_list(tempData);
                    }
                })
            }
            else if (result.isDenied) {
                Swal.close()
            }
        }
        )
    }

    function approve_agent(event, id, status) {
        event.preventDefault();
        Swal.fire({
            title: "Do you want to approve this agent?",
            icon: "warning",
            focusConfirm: false,
            showDenyButton: true,
            denyButtonText: "Cancel",
            confirmButtonText: "Approve"
        }).then((result) => {
            if (result.isConfirmed) {
                global_data.set_loader(true);
                var formData = {
                }
                userService.accept_user(global_data.token, id, formData).then((response) => {
                    global_data.set_loader(false);
                    if (response.status == 200) {
                        Swal.fire(
                            status == 'Approved',
                            "Agent approved successfully.",
                            "success"

                        )
                        var tempData = [...agents_list];
                        for (var i = 0; i < tempData.length; i++) {
                            if (tempData[i].id == id) {
                                tempData[i].is_approved = !tempData[i].is_approved;
                            }
                        }
                        set_agents_list(tempData);
                    }
                })
            }
            else if (result.isDenied) {
                Swal.close()
            }
        }
        )
    }

    function handle_page_change(event) {
        set_page(event)
        if (search == '') {
            history("/agents?page=" + event)
        } else {
            history("/agents?page=" + event + "&search=" + search)
        }
    }

    function viewDetail(id) {
        history(`/user_property?id=${id}&property_type=sale&status=all&page=1`)
    }
    return (

        <div class="content_area">

            <div class="tables_area">
                <h2 class="pull-left">Agents Listing</h2>

                {/* <a onClick={add_agent} class="blue_btn yellow_btn pull-right text-uppercase">Add New Agent</a> */}
                <div class="pull-right searchbtn_btn">
                    <input type="text" placeholder="Search By Name or Email" value={search} onChange={(event) => { set_search(event.target.value) }} />
                    <span>
                        <i class="fa fa-search" onClick={search_data}></i>
                    </span>
                </div>
                <div class="clear"></div>
                {agents_list.length == 0 ? <h3 className="text-center" style={{ marginTop: "40px" }}>No Data Found</h3> :
                    <div class="white_box">
                        <div class="table-responsive">

                            <table width="100%" cellspacing="0" cellpadding="0">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Profile Image</th>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                        {/* <th>Role</th> */}
                                        <th>Phone Number</th>
                                        <th>Company Name</th>
                                        <th>License Number</th>
                                        <th>Address</th>
                                        <th>About</th>
                                        <th>ACTION</th>
                                        <th>Properties</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {agents_list.length > 0 && agents_list.map((item, index) =>
                                        <tr>
                                            <td> {(20 * (page - 1)) + index + 1}</td>
                                            <td><img className="image-box-new" onClick={() => { handleShow(item.image) }} src={media_url + item.image} /> </td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.phone_number}</td>
                                            <td>{item.company_name}</td>
                                            <td>{item.license_number}</td>
                                            <td>{item.address}</td>
                                            <td>{item.about}</td>
                                            <td>

                                                {item.is_approved == true ? item.is_suspend == true ?
                                                    <button data-toggle="tooltip" data-placement="top" title="Activate" href="" onClick={(event) => { delete_agent(event, item.id, false) }} class="approvedd">Activate</button>
                                                    :
                                                    <button data-toggle="tooltip" data-placement="top" title="Suspend" href="" onClick={(event) => { delete_agent(event, item.id, true) }} class="approvedd">Suspend</button> :
                                                    <button data-toggle="tooltip" data-placement="top" title="Approve" href="" onClick={(event) => { approve_agent(event, item.id) }} class="approvedd">Approve</button>
                                                }

                                            </td>
                                            <td>{item.properties_count > 0 ? <a className="text_size" onClick={() => { viewDetail(item.id) }}> {item.properties_count}</a> : <a className="text_size"> {item.properties_count}</a>}</td>
                                        </tr>
                                    )}


                                </tbody>
                            </table>

                        </div>

                    </div>
                }
            </div>
            {agents_list.length != 0 &&
                <div class="paginationrow">
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={20}
                        totalItemsCount={total_count}
                        pageRangeDisplayed={5}
                        prevPageText="Previous"
                        nextPageText="Next"
                        onChange={(event) => { handle_page_change(event); }}
                    />

                </div>}

            <Modal className="image-modal" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered >
                <i className="fa fa-times cross-bt" onClick={handleClose} aria-hidden="true"></i>
                <img className="modal-img" src={image} height="500" width="100%"></img>
            </Modal>

        </div>



    );
};
export default AgentsList;