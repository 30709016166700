import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import GlobalContext from "../../../context/GlobalContext";
import { userService } from "../../../services/CommonServices";

const PartnerList = (props) => {
  var global_data = useContext(GlobalContext)
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [type, setType] = useState("attorney")
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [desc, setDesc] = useState("");
  const [descModal, setDescModal] = useState(false);
  let query = new URLSearchParams(window.location.search)
  const media_url = "https://api.realzon.net";
  const handleClose = () => setShow(false);
  let history = useNavigate();

  useEffect(() => {
    var temp_page = query.get('page');
    setPage(Number(temp_page))
    setType(query.get("type"))
    getPartnerList(temp_page, query.get("type"))
  }, [page, type])

  function getPartnerList(page, type) {
    if (type == "attorney") {
      global_data.set_loader(true)
      userService.attorney(page, global_data.token).then((res) => {
        setData(res.data.data)
        setTotalCount(res.data.totalItems)
        global_data.set_loader(false)
      }).catch((err) => {
        global_data.set_loader(false)
        toast.error(err.response.data.message)
      })
    } else if (type == "mortgage") {
      global_data.set_loader(true)
      userService.mortgage(page, global_data.token).then((res) => {
        setData(res.data.data)
        setTotalCount(res.data.totalItems)
        global_data.set_loader(false)
      }).catch((err) => {
        global_data.set_loader(false)
        toast.error(err.response.data.message)
      })
    }
  }

  function handle_page_change(event) {
    setPage(event);
    history(`/partner?type=${type}page=${event}`);
  }

  const handleShow = (image) => {
    setShow(true);
    setImage(media_url + image);
  };

  const addPartner = () => {
    history("/add_partner")
  }

  function handlePartnerChange(event) {
    history(`/partner?type=${event.target.value}&page=${page}`)
    setType(event.target.value)
  }

  const showDescription = (desc) => {
    setDesc(desc)
    setDescModal(true)
  }

  const changeStatus = (e, id, index, status) => {
    e.preventDefault();
    Swal.fire({
      title: "Do you want to " + (status == false ? "deactivate" : "activate") + " this " + type + "?",
      icon: "warning",
      focusConfirm: false,
      showDenyButton: true,
      denyButtonText: "Cancel",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        global_data.set_loader(true)
        var params = {
          "id": id,
          "status": status
        }
        if (type === "attorney") {
          userService.updateAttorneyStatus(params, global_data.token).then(res => {
            global_data.set_loader(false)
            Swal.fire(
              status == false ? 'Deactivated' : 'Activated',
              res.data.data,
              "success"
            )
            var temp = [...data]
            temp[index].status = !temp[index].status
            setData(temp)
          }).catch(err => {
            console.log('err --->', err);
            global_data.set_loader(false)
          })
        } else if (type == "mortgage") {
          userService.updateMortgageStatus(params, global_data.token).then(res => {
            global_data.set_loader(false)
            Swal.fire(
              status == false ? 'Deactivated' : 'Activated',
              res.data.data,
              "success"
            )
            var temp = [...data]
            temp[index].status = !temp[index].status
            setData(temp)
          }).catch(err => {
            console.log('err --->', err);
            global_data.set_loader(false)
          })
        }
      }
      else if (result.isDenied) {
        Swal.close()
      }
    })
  }

  return (
    <div class="content_area">
      <div class="tables_area">
        <div class="add-partner-heading-btn">
          <div class="add-partner-heading">
            <h2 class="pull-left">Partner Listing</h2>
          </div>

          <div className="d-flex">
            <div className="partner-type">
              <Form.Select className="pull-right" onChange={handlePartnerChange}>
                <option value="attorney" selected={type == "attorney"}>Attorney</option>
                <option value="mortgage" selected={type == "mortgage"}>Mortgage</option>
              </Form.Select>
            </div>
            <div class="add-partner-btn">

              <a onClick={addPartner} class="blue_btn pull-right text-uppercase Add-new-partner-btn">Add New Partner</a>
            </div>
          </div>
        </div>

        {data.length == 0 ? (
          <h3 className="text-center" style={{ marginTop: "40px" }}>
            No Data Found
          </h3>
        ) : (
          <div class="white_box">
            <div class="table-responsive">
              <table width="100%" cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Image</th>
                    <th>Logo</th>
                    <th>Name</th>
                    <th>Company Name</th>
                    {type == "mortgage" && <th>Title</th>}
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <th>Office Number</th>
                    {type == "attorney" && <th>Fax Number</th>}
                    {type == "mortgage" && <th>NMLS</th>}
                    {type == "mortgage" && <th>Branch NMLS</th>}
                    <th>Link</th>
                    <th>About</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 &&
                    data.map((item, index) => (
                      <tr>
                        <td> {20 * (page - 1) + index + 1}</td>
                        <td>
                          <img
                            className="image-box-new"
                            onClick={() => {
                              handleShow(item.image);
                            }}
                            src={media_url + item.image}
                          />{" "}
                        </td>
                        <td>
                          <img
                            className="image-box-new"
                            onClick={() => {
                              handleShow(item.logo);
                            }}
                            src={media_url + item.logo}
                          />{" "}
                        </td>
                        <td>{item.name}</td>
                        <td>{item.company_name}</td>
                        {type == "mortgage" && <td>{item.title}</td>}
                        <td>{item.email}</td>
                        <td>{item.phone_number}</td>
                        <td>{item.office_number}</td>
                        {type == "attorney" && <td>{item.fax_number}</td>}
                        {type == "mortgage" && <td>{item.nmls}</td>}
                        {type == "mortgage" && <td>{item.branch_nmls}</td>}
                        <td>{item.link}</td>
                        <td>{item.description.length < 200 ? item.description : item.description.slice(0, 180)} {item.description.length >= 200 && <span style={{ color: "#13497b", cursor: "pointer" }} onClick={() => showDescription(item.description)}>Read more...</span>}</td>
                        <td><button data-toggle="tooltip" data-placement="top" title={`${item.status ? "Deactivate" : "Activate"}`} onClick={(event) => changeStatus(event, item.id, index, !item.status)} class="approvedd">{item.status ? "Deactivate" : "Activate"}</button></td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {data.length != 0 && (
        <div class="paginationrow">
          <Pagination
            activePage={page}
            itemsCountPerPage={20}
            totalItemsCount={totalCount}
            pageRangeDisplayed={5}
            prevPageText="Previous"
            nextPageText="Next"
            onChange={(event) => {
              handle_page_change(event);
            }}
          />
        </div>
      )}

      <Modal
        className="image-modal"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <i
          className="fa fa-times cross-bt"
          onClick={handleClose}
          aria-hidden="true"
        ></i>
        <img className="modal-img" src={image} height="500" width="100%"></img>
      </Modal>
      <Modal
        className="desc-modal-data"
        show={descModal}
        onHide={() => { setDescModal(false); setTimeout(() => { setDesc("") }, 1000) }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <h4>Description</h4>
            <img
              src={require("../../../assets/images/close.png").default}
              onClick={() => { setDescModal(false); setTimeout(() => { setDesc("") }, 1000) }}
              alt="close"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {desc}
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PartnerList;
