import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import GlobalContext from "../../../context/GlobalContext";
import { userService } from "../../../services/CommonServices";

const InterestDetail = () => {
    const globalData = useContext(GlobalContext)
    const [Detail, setDetail] = useState([])
    const [propertyName, setPropertyName] = useState("");
    const [addedName, setAddedName]= useState("");
    let query = new URLSearchParams(window.location.search)
    let navigate = useNavigate()

    const GetDetails = () => {
        userService.interest_details(globalData.token, query.get("id")).then(res => {
            if (res.status === 200) {
                globalData.set_loader(false)
                setDetail(res.data.data)
                setPropertyName(res.data.property_name)
                setAddedName(res.data.added_by_name)
            }
        }).catch(err => {
            globalData.set_loader(false)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                globalData.set_token('')
                globalData.set_is_logged_in("false")
                localStorage.clear()
                toast.info("Your session has been expired, please login again!..")
                navigate('/login')
            }
        })
    }
    useEffect(() => {
        globalData.set_loader(true)
        GetDetails()
    }, [])

    
    return (
        
        <div className="content padding_section">
            <div className="top-listing-header-interest">
            <h2  style={{marginRight:"auto"}}>Properties Interest</h2>
            </div>
            <button onClick={()=>{navigate(-1)}}  className="back-btn" type="button" 
>Back</button>
            <div className="content-heading">
                <h4>Property Name: {propertyName}</h4>
                <h4>Added by: {addedName}</h4>
            </div>
            {Detail !=="" &&
                Detail?.map((detail)=>(
                <div className="main-form px-2">
                  
                    <div className="row mt-4">
                        <aside className="col-md-4 mt-4">
                            <div className="view-content">
                                <p className="mb-0">Name</p>
                                <h5 className="mt-2">{detail.name}</h5>
                            </div>
                        </aside>
                        <aside className="col-md-4 mt-4">
                            <div className="view-content">
                                <p className="mb-0">Phone Number</p>
                                <h5 className="mt-2">{detail.phone_number}</h5>
                            </div>
                        </aside>
                        <aside className="col-md-4 mt-4">
                            <div className="view-content">
                                <p className="mb-0">Email</p>
                                <h5 className="mt-2">{detail.email ? detail.email : "-"}</h5>
                            </div>
                        </aside>
                    </div>
                </div>               
                ))
            } 
        </div>
    );
}
export default InterestDetail;