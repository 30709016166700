import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
// import dummy from "../../assets/images/dummy.png"
import Autocomplete from "react-google-autocomplete";
import { userService } from "../../../services/CommonServices";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
// import 'bootstrap/dist/css/bootstrap.min.css';

const EditProperty = () => {
  const media_url = "https://api.realzon.net";
  let navigate = useNavigate();
  let globalData = useContext(GlobalContext);
  const location = useLocation();
  const [query] = useSearchParams();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    navigate(-1);
  };
  const handleShow = () => setShow(true);
  const [type, setType] = useState("");
  const [auctionTypes, setAuctionType] = useState("");
  const [AucImgErr, setAucImgErr] = useState("");
  const [saleImgErr, setSaleImgErr] = useState("");
  const [auctionImgUrl, setAuctionImgUrl] = useState([]);
  const [saleImgUrl, setSaleImgUrl] = useState([]);
  const [images, setImages] = useState([]);
  const [saleimages, setSaleImages] = useState([]);
  const [approved, set_approved] = useState(false);
  const [editable, set_editable] = useState(location.state > 0 ? true : false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    control,
    getValues,
  } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    control: control1,
    setValue: setValue1,
    watch: watch1,
    getValues: getValues1,
  } = useForm();

  //ONLINE AUCTION PROPERTY SUBMIT
  const onSubmit = (data) => {
    console.log(data);
    if (auctionImgUrl.length <= 0) {
      document.getElementById("auc33").focus({ preventScroll: false });
      setAucImgErr("please upload images.");
      return false;
    }
    globalData.set_loader(true);
    const payload = new FormData();

    images.map((item, index) =>
      payload.append(`images${index}`, images[index])
    );
    payload.append("name", data.name);
    payload.append("image_count", images.length);
    if (data.address.geometry !== undefined) {
      payload.append("address", data.address.formatted_address);
      payload.append("latitude", data.address.geometry.location.lat());
      payload.append("longitude", data.address.geometry.location.lng());
    }
    if (data.auctionType == "online") {
      payload.append("number_of_bedrooms", data.bedRooms);
      payload.append("number_of_half_bathrooms", data.fullBath);
      payload.append("number_of_full_bathrooms", data.halfBath);
      payload.append("description", data.description);
      payload.append("listing_id", data.propertyId);
      payload.append("sf_area", data.sqft);
      payload.append("sf_dimensions", data.dimension);
      payload.append("built_year", data.yearBuilt);
      payload.append("lot_dimensions", data.lotSize);
      payload.append("sale_type", data.saleType);
      payload.append("occupied", data.occupied);
      payload.append("country", data.country);
      payload.append("mls_id", data.mlsId);
      payload.append("bid_deposit", data.bidRegister);
      payload.append("buyers_premium", data.buyerPremium);
      payload.append("earnest_money_deposit", data.earnestMoney);
      payload.append("cash_only_purchase", data.cashOnlyPurchase);
      payload.append("finance_available", data.financingAvailable);
      payload.append("start_date", moment(data.startDate).format("YYYY-MM-DD"));
      payload.append("start_time", moment(data.startTime).format("HH:mm"));
      payload.append("end_date", moment(data.endDate).format("YYYY-MM-DD"));
      payload.append("end_time", moment(data.endTime).format("HH:mm"));
      payload.append("property_type", "auction");
      payload.append("number_of_kitchen", data.kitchenNumber);
      payload.append("number_of_balcony", data.balconyNumber);
      payload.append(
        "possession_date",
        moment(data.possesionDate).format("YYYY-MM-DD")
      );
      payload.append("auction_type", data.auctionType);
      payload.append("entry_fee", data.entryFees);
      payload.append("is_approved", true);
      payload.append("base_price", data.basePrice);
    } else {
      payload.append("auction_type", data.auctionType);
      payload.append("is_approved", true);
      payload.append("country", data.country);
      // payload.append("base_price", data.basePrice);
      payload.append("property_type", "auction");
      payload.append("start_date", moment(data.startDate).format("YYYY-MM-DD"));
      payload.append("start_time", moment(data.startTime).format("HH:mm"));
      payload.append(
        "auction_date",
        moment(data.auctionDate).format("YYYY-MM-DD")
      );
      payload.append("auction_time", moment(data.auctionTime).format("HH:mm"));
      // payload.append("possession_date", moment(data.possesionDate).format("YYYY-MM-DD"));
      payload.append("total_area", data.totalArea);
      payload.append(
        "auction_location",
        data.auctionLocation.formatted_address
      );
      payload.append("line", data.line);
      payload.append("plaintiff", data.plaintiff);
      payload.append("defendant", data.defendant);
      payload.append("judgment", moment(data.judgment).format("YYYY-MM-DD"));
      payload.append("index_number", data.indexNumber);
      payload.append("referee_number", data.refereeNumber);
      payload.append("plaintiff_attorney", data.plaintiffAttorney);
      payload.append("foreclosure_type", data.foreclosureType);
      payload.append("auction_note", data.auctionNote);
      payload.append("description", data.description);
    }

    userService
      .update_property(payload, query.get("id"), globalData.token)
      .then((response) => {
        if (response.status === 200) {
          globalData.set_loader(false);
          navigate(-1);
        }
      })
      .catch((err) => {
        globalData.set_loader(false);
        if (err.response.status === 400) {
          toast.error(err.response.data.message);
        }
        if (err.response.status === 401) {
          globalData.setToken("");
          localStorage.clear();
          toast.info("Your session has been expired, please login again!..");
          navigate("/");
        }
      });
  };

  // SALE PROPERTY SUBMIT
  const onSaleSubmit = (data) => {
    console.log(data);
    if (saleImgUrl.length <= 0) {
      document.getElementById("sale22").focus({ preventScroll: false });
      setSaleImgErr("please upload images.");
      return false;
    }
    globalData.set_loader(true);
    let payload = new FormData();
    payload.append("image_count", saleimages.length);
    saleimages.map((item, index) => payload.append(`images${index}`, item));
    payload.append("name", data.name);

    if (data.saleAddress.geometry !== undefined) {
      payload.append("address", data.saleAddress.formatted_address);
      payload.append("latitude", data.saleAddress.geometry.location.lat());
      payload.append("longitude", data.saleAddress.geometry.location.lng());
    }
    payload.append("number_of_bedrooms", data.saleBedroom);
    payload.append("number_of_half_bathrooms", data.saleHalfBath);
    payload.append("number_of_full_bathrooms", data.saleFullBath);
    payload.append("description", data.description);
    payload.append("total_sqft_area", data.saleSqft);
    payload.append("dimensions", data.saleDimension);
    payload.append("total_lot_sqft_area", data.lotSqft);
    payload.append("lot_dimensions", data.lotDimension);
    payload.append("built_year", data.yearBuilt);
    payload.append("parcel_id", data.parcelId);
    payload.append("list_price", data.saleListPrice);
    payload.append("zoning", data.saleZoning);
    payload.append("cofo", data.cofo);
    payload.append("style", data.saleStyle);
    payload.append("taxes", data.saleTax);
    payload.append("condition", data.saleCondition);
    payload.append("total_rooms_in_main_unit", data.totalRoomMainUnit);
    payload.append("garage", data.garage);
    payload.append("water_view", data.waterView);
    payload.append("heating", data.saleHeating);
    payload.append("cooling", data.saleCooling);
    payload.append("ac", data.ac);
    payload.append("heat_source", data.heatSource);
    payload.append("heat_system", data.heatSystem);
    payload.append("basement", data.basement);
    payload.append("basement_type", data.basement_type);
    payload.append("sewer", data.sewer);
    payload.append("electricity", data.electric);
    payload.append("kitchen", data.kitchen);
    payload.append("living_room", data.livingRoom);
    payload.append("main_bath", data.mainBath);
    payload.append("master_bedroom", data.masterBedRoom);
    payload.append("exterior", data.exterior);
    payload.append("yard", data.yard);
    payload.append("pool", data.pool);
    payload.append("property_type", "sale");
    payload.append("number_of_kitchen", data.kitchenNumber);
    payload.append("number_of_balcony", data.balconyNumber);
    payload.append(
      "possession_date",
      moment(data.possesionDate).format("YYYY-MM-DD")
    );
    payload.append("is_approved", true);

    userService
      .update_property(payload, query.get("id"), globalData.token)
      .then((response) => {
        if (response.status === 200) {
          globalData.set_loader(false);
          navigate(-1);
        }
      })
      .catch((err) => {
        globalData.set_loader(false);
        if (err.response.status === 400) {
          toast.error(err.response.data.message);
        }
        if (err.response.status === 401) {
          globalData.set_token("");
          globalData.set_is_logged_in("false");
          localStorage.clear();
          toast.info("Your session has been expired, please login again!..");
          navigate("/");
        }
      });
  };

  const handleImageUrl = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].type.split("/")[0] == "image") {
        var temp1 = [...images];
        var temp = [...auctionImgUrl];
        for (var i = 0; i < e.target.files.length; i++) {
          temp1.push(e.target.files[i]);

          setImages(temp1);

          temp.push({ id: i, image: URL.createObjectURL(e.target.files[i]) });

          setAuctionImgUrl(temp);
        }
      } else {
        toast.error("Select a valid image.");
      }
    }
  };

  const handleSaleImageUrl = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].type.split("/")[0] == "image") {
        var temp1 = [...saleimages];
        var temp = [...saleImgUrl];
        for (var i = 0; i < e.target.files.length; i++) {
          temp1.push(e.target.files[i]);

          setSaleImages(temp1);

          temp.push({ id: i, image: URL.createObjectURL(e.target.files[i]) });

          setSaleImgUrl(temp);
        }
      } else {
        toast.error("Select a valid image.");
      }
    }
  };

  const DeleteSaleImages = (e, index, id) => {
    globalData.set_loader(true);
    var temp = [...saleimages];
    var temp1 = [...saleImgUrl];
    temp.splice(index, 1);
    temp1.splice(index, 1);
    setSaleImages(temp);
    setSaleImgUrl(temp1);
    const payload = {
      image_id: id,
      property_id: query.get("id"),
    };
    userService
      .delete_image(payload, globalData.token)
      .then((res) => {
        if (res.status === 200) {
          globalData.set_loader(false);
        }
      })
      .catch((err) => {
        globalData.set_loader(false);
        if (err.response.status === 400) {
          toast.error(err.response.data.message);
        }
        if (err.response.status === 401) {
          globalData.set_token("");
          globalData.set_is_logged_in("false");
          localStorage.clear();
          toast.info("Your session has been expired, please login again!..");
          navigate("/");
        }
      });
  };

  const DeleteImages = (e, index, id) => {
    var temp = [...images];
    var temp1 = [...auctionImgUrl];
    temp.splice(index, 1);
    temp1.splice(index, 1);
    setImages(temp);
    setAuctionImgUrl(temp1);
    const payload = {
      image_id: id,
      property_id: query.get("id"),
    };
    userService
      .delete_image(payload, globalData.token)
      .then((res) => {
        if (res.status === 200) {
          globalData.set_loader(false);
        }
      })
      .catch((err) => {
        globalData.set_loader(false);
        if (err.response.status === 400) {
          toast.error(err.response.data.message);
        }
        if (err.response.status === 401) {
          globalData.set_token("");
          globalData.set_is_logged_in("false");
          localStorage.clear();
          toast.info("Your session has been expired, please login again!..");
          navigate("/");
        }
      });
  };

  const GetPropertyDetails = () => {
    globalData.set_loader(true);
    userService
      .get_property(query.get("id"), globalData.token)
      .then((response) => {
        if (response.status === 200) {
          globalData.set_loader(false);
          setType(response.data.data.property_type);
          set_approved(response.data.data.is_approved);
          if (response.data.data.property_type === "sale") {
            var temp = [...saleImgUrl];
            response.data.data.images.map((image, index) =>
              temp.push({ id: image.id, image: media_url + image.image })
            );
            setSaleImgUrl(temp);
            setValue1("basement_type", response.data.data.basement_type);
            setValue1("name", response.data.data.name);
            setValue1("saleAddress", {
              formatted_address: response.data.data.address,
            });
            setValue1("parcelId", response.data.data.parcel_id);
            setValue1(
              "saleListPrice",
              response.data.data.list_price.split(".")[0]
            );
            setValue1("saleZoning", response.data.data.zoning);
            setValue1("saleStyle", response.data.data.style);
            setValue1("yearBuilt", response.data.data.built_year);
            setValue1("saleTax", response.data.data.taxes.split(".")[0]);
            setValue1("saleCondition", response.data.data.condition);
            setValue1("saleSqft", response.data.data.total_sqft_area);
            setValue1("saleDimension", response.data.data.dimensions);
            setValue1("lotSqft", response.data.data.total_lot_sqft_area);
            setValue1("lotDimension", response.data.data.lot_dimensions);
            setValue1(
              "totalRoomMainUnit",
              response.data.data.total_rooms_in_main_unit
            );
            setValue1("saleBedroom", response.data.data.number_of_bedrooms);
            setValue1(
              "saleFullBath",
              response.data.data.number_of_full_bathrooms
            );
            setValue1(
              "saleHalfBath",
              response.data.data.number_of_half_bathrooms
            );
            setValue1("saleHeating", response.data.data.heating);
            setValue1("saleCooling", response.data.data.cooling);
            setValue1("cofo", String(response.data.data.cofo));
            setValue1("garage", String(response.data.data.garage));
            setValue1("waterView", String(response.data.data.water_view));
            setValue1("ac", response.data.data.ac);
            setValue1("heatSource", response.data.data.heat_source);
            setValue1("heatSystem", response.data.data.heat_system);
            setValue1("basement", String(response.data.data.basement));

            setValue1("kitchen", response.data.data.kitchen);
            setValue1("mainBath", response.data.data.main_bath);
            setValue1("livingRoom", response.data.data.living_room);
            setValue1("exterior", response.data.data.exterior);
            setValue1("masterBedRoom", response.data.data.master_bedroom);
            setValue1("pool", response.data.data.pool);
            setValue1("yard", response.data.data.yard);
            setValue1("sewer", response.data.data.sewer);
            setValue1("electric", response.data.data.electricity);
            setValue1("description", response.data.data.description);
            setValue1("balconyNumber", response.data.data.number_of_balcony);
            setValue1("kitchenNumber", response.data.data.number_of_kitchen);
            setValue1(
              "possesionDate",
              moment(response.data.data.possession_date).format("MM/DD/yyyy")
            );
          } else {
            if (response.data.data.auction_type == "online") {
              var temp = [...auctionImgUrl];
              response.data.data.images.map((image, index) =>
                temp.push({ id: image.id, image: media_url + image.image })
              );
              setAuctionImgUrl(temp);
              setAuctionType(response.data.data.auction_type);
              setValue("name", response.data.data.name);
              setValue("address", {
                formatted_address: response.data.data.address,
              });
              setValue("propertyId", response.data.data.listing_id);
              setValue("saleType", response.data.data.sale_type);
              setValue("lotSize", response.data.data.lot_dimensions);
              setValue("country", response.data.data.country);
              setValue("yearBuilt", response.data.data.built_year);
              setValue("mlsId", response.data.data.mls_id);
              setValue(
                "bidRegister",
                response.data.data.bid_deposit.split(".")[0]
              );
              setValue("sqft", response.data.data.sf_area);
              setValue("dimension", response.data.data.sf_dimensions);
              setValue("bedRooms", response.data.data.number_of_bedrooms);
              setValue("fullBath", response.data.data.number_of_full_bathrooms);
              setValue("halfBath", response.data.data.number_of_half_bathrooms);
              setValue("occupied", String(response.data.data.occupied));
              setValue(
                "cashOnlyPurchase",
                String(response.data.data.cash_only_purchase)
              );
              setValue(
                "financingAvailable",
                String(response.data.data.finance_available)
              );
              setValue(
                "buyerPremium",
                response.data.data.buyers_premium.split(".")[0]
              );
              setValue(
                "earnestMoney",
                response.data.data.earnest_money_deposit.split(".")[0]
              );
              setValue("description", response.data.data.description);

              setValue(
                "startDate",
                moment(response.data.data.start_date).format("MM/DD/yyyy")
              );
              setValue("startTime", moment(response.data.data.show_start_time));
              setValue(
                "endDate",
                moment(response.data.data.end_date).format("MM/DD/yyyy")
              );
              setValue("endTime", moment(response.data.data.show_end_time));
              setValue("balconyNumber", response.data.data.number_of_balcony);
              setValue("kitchenNumber", response.data.data.number_of_kitchen);
              setValue(
                "possesionDate",
                moment(response.data.data.possession_date).format("MM/DD/yyyy")
              );
              setValue("auctionType", response.data.data.auction_type);
              setValue("entryFees", response.data.data.entry_fee.split(".")[0]);
              setValue("basePrice", response.data.data.base_price);
            } else {
              var temp = [...auctionImgUrl];
              response.data.data.images.map((image, index) =>
                temp.push({ id: image.id, image: media_url + image.image })
              );
              setAuctionImgUrl(temp);
              setAuctionType(response.data.data.auction_type);
              setValue("name", response.data.data.name);
              setValue("address", {
                formatted_address: response.data.data.address,
              });
              setValue("country", response.data.data.country);
              setValue("description", response.data.data.description);
              setValue(
                "startDate",
                moment(response.data.data.start_date).format("MM/DD/yyyy")
              );
              setValue("startTime", moment(response.data.data.show_start_time));
              setValue(
                "endDate",
                moment(response.data.data.end_date).format("MM/DD/yyyy")
              );
              setValue("endTime", moment(response.data.data.show_end_time));
              setValue("auctionType", response.data.data.auction_type);
              setValue("auctionDate", response.data.data);
              setValue("basePrice", response.data.data.base_price);
              setValue(
                "auctionDate",
                moment(response.data.data.auction_date).format("MM/DD/YYYY")
              );
              setValue(
                "auctionTime",
                moment(response.data.data.show_auction_time)
              );
              setValue(
                "possesionDate",
                moment(response.data.data.possession_date).format("MM/DD/yyyy")
              );
              setValue("totalArea", response.data.data.total_area);
              setValue("auctionLocation", {
                formatted_address: response.data.data.auction_location,
              });
              setValue("line", response.data.data.line);
              setValue("plaintiff", response.data.data.plaintiff);
              setValue("defendant", response.data.data.defendant);
              setValue(
                "judgment",
                moment(response.data.data.judgment).format("MM/DD/yyyy")
              );
              setValue("indexNumber", response.data.data.index_number);
              setValue("refereeNumber", response.data.data.referee_number);
              setValue(
                "plaintiffAttorney",
                response.data.data.plaintiff_attorney
              );
              setValue("foreclosureType", response.data.data.foreclosure_type);
              setValue("auctionNote", response.data.data.auction_note);
              setValue("description", response.data.data.description);
            }
          }
        }
      })
      .catch((err) => {
        globalData.set_loader(false);
        // if (err.response.status === 400) {
        //     toast.error(err.response.data.message)
        // }
        // if (err.respoonse.status === 401) {
        //     globalData.setToken('')
        //     localStorage.clear()
        //     toast.info("Your session has been expired, please login again!..")
        //     navigate('/')
        // }
      });
  };
  useEffect(() => {
    {
      globalData.token && GetPropertyDetails();
    }
  }, [globalData.token]);

  return (
    <>
      <section className="login-area forgot-area add-listing-page">
        <div className="container">
          <div className="login-box-area">
            <div className="row justify-content-center">
              <aside className="col-md-11">
                <div className="login-box">
                  <div className="login-form">
                    <button
                      onClick={() => {
                        navigate(-1);
                      }}
                      className="back-btn edit-property-back-btn"
                      type="button"
                    >
                      Back
                    </button>
                    <h2 className="text-center">Update Property</h2>
                    <Tabs
                      defaultActiveKey="sale"
                      activeKey={type}
                      transition={false}
                      id="listing-tab"
                      value={type}
                      className="mb-3 listing-tab-top"
                      onSelect={(key) => {
                        setType(key);
                      }}
                    >
                      <Tab
                        eventKey="sale"
                        disabled={type === "auction"}
                        title="FEATURED"
                      >
                        <form id="sale" onSubmit={handleSubmit1(onSaleSubmit)}>
                          <Row>
                            <Col md={12}>
                              {saleImgUrl.length < 4 ? (
                                <div className="my-3 text-center">
                                  <div className="upload-img">
                                    <Form.Control
                                      type="file"
                                      onChange={(e) => handleSaleImageUrl(e)}
                                      onClick={(e) => (e.target.value = "")}
                                    />

                                    <img
                                      id="sale22"
                                      src={
                                        require("../../../assets/images/download.png")
                                          .default
                                      }
                                      className="upload-img"
                                    ></img>
                                    <p>
                                      Upload{" "}
                                      {globalData.userType === "seller"
                                        ? "property"
                                        : "listing"}{" "}
                                      images/banners here
                                    </p>
                                  </div>
                                  {saleImgErr && (
                                    <span style={{ color: "red" }}>
                                      {saleImgErr}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="uploaded-pictures">
                                {saleImgUrl.map((img, index) => (
                                  <div
                                    className="picture-one-new"
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      src={img.image}
                                      className="upload-img"
                                    ></img>
                                    <i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                      onClick={(e) =>
                                        DeleteSaleImages(e, index, img.id)
                                      }
                                    ></i>
                                  </div>
                                ))}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Property Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  maxLength={30}
                                  {...register1("name", {
                                    required: "Name is required.",
                                  })}
                                  placeholder="Enter Name"
                                />
                                {errors1.name && (
                                  <span style={{ color: "red" }}>
                                    {errors1.name.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <Controller
                                render={({
                                  field: { onChange: onChange1, name, ref },
                                  fieldState: { error: error1 },
                                }) => (
                                  <div className="my-3">
                                    <label className="form-label">
                                      Address
                                    </label>
                                    <Autocomplete
                                      apiKey="AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g"
                                      className="form-control"
                                      value={
                                        getValues1("saleAddress")
                                          ?.formatted_address
                                      }
                                      options={{
                                        types: ["geocode", "establishment"],
                                      }}
                                      placeholder="eg. 857 Pineknoll St. Fort Lauderdale, FL 33308"
                                      onChange={() =>
                                        setValue1("saleAddress", "")
                                      }
                                      onPlaceSelected={(place) =>
                                        onChange1(place)
                                      }
                                    />

                                    {error1?.message && (
                                      <span style={{ color: "red" }}>
                                        {error1.message}
                                      </span>
                                    )}
                                  </div>
                                )}
                                control={control1}
                                name="saleAddress"
                                rules={{ required: "Please select address." }}
                              />
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Parcel ID</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register1("parcelId", {
                                    required: "Parcel id is required.",
                                    pattern: {
                                      value: /^[\d-\s]+$/,
                                      message:
                                        "Only numeric characters with hyphen are allowed.",
                                    },
                                  })}
                                  placeholder="Enter parcel id"
                                />
                                {errors1.parcelId && (
                                  <span style={{ color: "red" }}>
                                    {errors1.parcelId.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">List Price</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={10}
                                  id=""
                                  placeholder="Enter list price"
                                  {...register1("saleListPrice", {
                                    required: "List price is required.",
                                    pattern: {
                                      value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                />
                                {errors1.saleListPrice && (
                                  <span style={{ color: "red" }}>
                                    {errors1.saleListPrice.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              {/* // <div className="my-3"> */}

                              <div className="my-3">
                                <label className="form-label">Zoning</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register1("saleZoning", {
                                    required: "Zoning is required.",
                                  })}
                                  placeholder="Enter zoning"
                                />
                                {errors1.saleZoning && (
                                  <span style={{ color: "red" }}>
                                    {errors1.saleZoning.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Style</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  maxLength={30}
                                  placeholder="Enter style"
                                  {...register1("saleStyle", {
                                    required: "Style is required.",
                                  })}
                                />
                                {errors1.saleStyle && (
                                  <span style={{ color: "red" }}>
                                    {errors1.saleStyle.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Year built</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={4}
                                  id=""
                                  {...register1("yearBuilt", {
                                    required: "Year is required.",
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                  placeholder="Enter Year built"
                                />
                                {errors1.yearBuilt && (
                                  <span style={{ color: "red" }}>
                                    {errors1.yearBuilt.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Taxes</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  maxLength={10}
                                  placeholder="Enter taxes"
                                  {...register1("saleTax", {
                                    required: "Taxes is required.",
                                    pattern: {
                                      value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                />
                                {errors1.saleTax && (
                                  <span style={{ color: "red" }}>
                                    {errors1.saleTax.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Condition</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={30}
                                  id=""
                                  {...register1("saleCondition", {
                                    required: "Condition is required.",
                                  })}
                                  placeholder="Enter condition"
                                />
                                {errors1.saleCondition && (
                                  <span style={{ color: "red" }}>
                                    {errors1.saleCondition.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Building Sqft and Dimensions
                                </label>
                                <Row className="d-flex align-items-center">
                                  <Col md={5} className="pr-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      maxLength={10}
                                      placeholder="Enter Sqft"
                                      {...register1("saleSqft", {
                                        required: "Sqft is required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors1.saleSqft && (
                                      <span style={{ color: "red" }}>
                                        {errors1.saleSqft.message}
                                      </span>
                                    )}
                                  </Col>
                                  <Col md={1}>
                                    <span>X</span>
                                  </Col>

                                  <Col md={6} className="pl-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      maxLength={10}
                                      placeholder="Enter dimensions"
                                      {...register1("saleDimension", {
                                        required: "Dimensions are required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors1.saleDimension && (
                                      <span style={{ color: "red" }}>
                                        {errors1.saleDimension.message}
                                      </span>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Lot Sqft and Dimensions
                                </label>
                                <Row className="d-flex align-items-center">
                                  <Col md={5} className="pr-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength={10}
                                      id=""
                                      {...register1("lotSqft", {
                                        required: "Sqft is required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                      placeholder="Enter sq"
                                    />
                                    {errors1.lotSqft && (
                                      <span style={{ color: "red" }}>
                                        {errors1.lotSqft.message}
                                      </span>
                                    )}
                                  </Col>
                                  <Col md={1}>
                                    <span>X</span>
                                  </Col>

                                  <Col md={6} className="pl-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      maxLength={10}
                                      {...register1("lotDimension", {
                                        required: "Dimension is required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                      placeholder="Enter dimension"
                                    />
                                    {errors1.lotDimension && (
                                      <span style={{ color: "red" }}>
                                        {errors1.lotDimension.message}
                                      </span>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Total rooms Main unit
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={2}
                                  id=""
                                  placeholder="Enter total rooms"
                                  {...register1("totalRoomMainUnit", {
                                    required: "Rooms main unit is required.",
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                />
                                {errors1.totalRoomMainUnit && (
                                  <span style={{ color: "red" }}>
                                    {errors1.totalRoomMainUnit.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Bedrooms</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={2}
                                  id=""
                                  {...register1("saleBedroom", {
                                    required: "Bedrooms is required.",
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                  placeholder="Enter bedrooms"
                                />
                                {errors1.saleBedroom && (
                                  <span style={{ color: "red" }}>
                                    {errors1.saleBedroom.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Bath</label>
                                <Row>
                                  <Col md={6}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength={2}
                                      id=""
                                      placeholder="Number of full bath"
                                      {...register1("saleFullBath", {
                                        required:
                                          "Number of full bath is required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors1.saleFullBath && (
                                      <span style={{ color: "red" }}>
                                        {errors1.saleFullBath.message}
                                      </span>
                                    )}
                                  </Col>
                                  <Col md={6}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength={2}
                                      id=""
                                      placeholder="Number of half bath"
                                      {...register1("saleHalfBath", {
                                        required: "Number of half is required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors1.saleHalfBath && (
                                      <span style={{ color: "red" }}>
                                        {errors1.saleHalfBath.message}
                                      </span>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Kitchen</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={2}
                                  id=""
                                  {...register1("kitchenNumber", {
                                    required: "Kitchens is required.",
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                  placeholder="Enter number of kitchen"
                                />
                                {errors1.kitchenNumber && (
                                  <span style={{ color: "red" }}>
                                    {errors1.kitchenNumber.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Balcony</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  maxLength={2}
                                  id=""
                                  {...register1("balconyNumber", {
                                    required: "Balcony is required.",
                                    pattern: {
                                      value: /^[0-9]*$/,
                                      message:
                                        "Only numeric characters are allowed.",
                                    },
                                  })}
                                  placeholder="Enter number of balcony"
                                />
                                {errors1.balconyNumber && (
                                  <span style={{ color: "red" }}>
                                    {errors1.balconyNumber.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="row">
                                <div className="col-md-12">
                                  <label>Possession Date :</label>
                                </div>
                                <Controller
                                  control={control1}
                                  name="possesionDate"
                                  rules={{
                                    required: "Possesion date is required.",
                                  }}
                                  render={({ field: { onChange, value } }) => (
                                    <div className="col-md-8">
                                      <DatePicker
                                        className="form-control"
                                        value={value}
                                        dateFormat="MM/dd/yyyy"
                                        placeholderText="MM/DD/YYYY"
                                        onKeyDown={(e) => e.preventDefault()}
                                        minDate={new Date()}
                                        onChange={(date) =>
                                          onChange(
                                            moment(date).format("MM/DD/YYYY")
                                          )
                                        }
                                      />

                                      {errors1.possesionDate && (
                                        <p
                                          style={{
                                            color: "red",
                                            textAlign: "left",
                                          }}
                                        >
                                          {errors1.possesionDate.message}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Heating & cooling
                                </label>
                                <Row>
                                  <Col md={6}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength={3}
                                      id=""
                                      {...register1("saleHeating", {
                                        required: "Heating is required.",
                                      })}
                                      placeholder="Enter heating"
                                    />
                                    {errors1.saleHeating && (
                                      <span style={{ color: "red" }}>
                                        {errors1.saleHeating.message}
                                      </span>
                                    )}
                                  </Col>
                                  <Col md={6}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength={3}
                                      id=""
                                      {...register1("saleCooling", {
                                        required: "cooling is required.",
                                      })}
                                      placeholder="Enter cooling"
                                    />
                                    {errors1.saleCooling && (
                                      <span style={{ color: "red" }}>
                                        {errors1.saleCooling.message}
                                      </span>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            </Col>

                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Cofo</label>
                                <Controller
                                  control={control1}
                                  name="cofo"
                                  rules={{ required: "Please select one." }}
                                  render={({ field: { onChange, value } }) => (
                                    <div className="mb-3 d-flex">
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        value={true}
                                        checked={value === "true"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group1"
                                        type="radio"
                                        id="radio17"
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        value={false}
                                        checked={value === "false"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group1"
                                        type="radio"
                                        id="radio27"
                                      />
                                    </div>
                                  )}
                                />
                                {errors1.cofo && (
                                  <span style={{ color: "red" }}>
                                    {errors1.cofo.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Garage (location)
                                </label>
                                <Controller
                                  control={control1}
                                  name="garage"
                                  rules={{ required: "Please select one." }}
                                  render={({ field: { onChange, value } }) => (
                                    <div className="mb-3  d-flex">
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        value={true}
                                        checked={value === "true"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group41"
                                        type="radio"
                                        id="radio67"
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        value={false}
                                        checked={value === "false"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group41"
                                        type="radio"
                                        id="radio66"
                                      />
                                    </div>
                                  )}
                                />
                                {errors1.garage && (
                                  <span style={{ color: "red" }}>
                                    {errors1.garage.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Water view</label>
                                <Controller
                                  control={control1}
                                  name="waterView"
                                  rules={{ required: "Please select one." }}
                                  render={({ field: { onChange, value } }) => (
                                    <div className="mb-3  d-flex">
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        value={true}
                                        checked={value === "true"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group2"
                                        type="radio"
                                        id="radio15"
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        name="group2"
                                        value={false}
                                        checked={value === "false"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        type="radio"
                                        id="radio25"
                                      />
                                    </div>
                                  )}
                                />
                                {errors1.waterView && (
                                  <span style={{ color: "red" }}>
                                    {errors1.waterView.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">A/C</label>
                                <Controller
                                  control={control1}
                                  name="ac"
                                  rules={{ required: "Please select one." }}
                                  render={({ field: { onChange, value } }) => (
                                    <div className="mb-3  d-flex">
                                      <Form.Check
                                        inline
                                        label="Central"
                                        value="CENTRAL"
                                        checked={value === "CENTRAL"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group31"
                                        type="radio"
                                        id="radio75"
                                      />
                                      <Form.Check
                                        inline
                                        label="Units"
                                        value="UNITS"
                                        checked={value === "UNITS"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group31"
                                        type="radio"
                                        id="radio76"
                                      />
                                      <Form.Check
                                        inline
                                        label="None"
                                        value="NONE"
                                        checked={value === "NONE"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group31"
                                        type="radio"
                                        id="radio77"
                                      />
                                    </div>
                                  )}
                                />
                                {errors1.ac && (
                                  <span style={{ color: "red" }}>
                                    {errors1.ac.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Heat source
                                </label>
                                <Controller
                                  control={control1}
                                  name="heatSource"
                                  rules={{ required: "Please select one." }}
                                  render={({ field: { onChange, value } }) => (
                                    <div className="mb-3  d-flex">
                                      <Form.Check
                                        inline
                                        label="Electric"
                                        value="ELECTRIC"
                                        checked={value === "ELECTRIC"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group3"
                                        type="radio"
                                        id="radio13"
                                      />
                                      <Form.Check
                                        inline
                                        label="Gas"
                                        value="GAS"
                                        checked={value === "GAS"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group3"
                                        type="radio"
                                        id="radio23"
                                      />
                                      <Form.Check
                                        inline
                                        label="Oil"
                                        value="OIL"
                                        checked={value === "OIL"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group3"
                                        type="radio"
                                        id="radio24"
                                      />
                                    </div>
                                  )}
                                />
                                {errors1.heatSource && (
                                  <span style={{ color: "red" }}>
                                    {errors1.heatSource.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Heat system
                                </label>
                                <Controller
                                  control={control1}
                                  name="heatSystem"
                                  rules={{ required: "Please select one." }}
                                  render={({ field: { onChange, value } }) => (
                                    <div className="mb-3  d-flex">
                                      <Form.Check
                                        inline
                                        label="Forced air"
                                        value="FORCED_AIR"
                                        checked={value === "FORCED_AIR"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group24"
                                        type="radio"
                                        id="radio83"
                                      />
                                      <Form.Check
                                        inline
                                        label="Steam"
                                        value="STEAM"
                                        checked={value === "STEAM"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group24"
                                        type="radio"
                                        id="radio84"
                                      />
                                      <Form.Check
                                        inline
                                        label="Hot water"
                                        value="HOT_WATER"
                                        checked={value === "HOT_WATER"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group24"
                                        type="radio"
                                        id="radio85"
                                      />
                                      <Form.Check
                                        inline
                                        label="Other"
                                        value="OTHER"
                                        checked={value === "OTHER"}
                                        name="group24"
                                        type="radio"
                                        id="radio86"
                                      />
                                    </div>
                                  )}
                                />
                                {errors1.heatSystem && (
                                  <span style={{ color: "red" }}>
                                    {errors1.heatSystem.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Basement</label>
                                <Controller
                                  control={control1}
                                  name="basement"
                                  rules={{ required: "Please select one." }}
                                  render={({ field: { onChange, value } }) => (
                                    <div className="mb-3 d-flex">
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        value={true}
                                        checked={value === "true"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group4"
                                        type="radio"
                                        id="radio1"
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        value={false}
                                        checked={value === "false"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group4"
                                        type="radio"
                                        id="radio2"
                                      />
                                    </div>
                                  )}
                                />
                                {errors1.basement && (
                                  <span style={{ color: "red" }}>
                                    {errors1.basement.message}
                                  </span>
                                )}
                                {watch1("basement") == "true" && (
                                  <Controller
                                    control={control1}
                                    name="basement_type"
                                    rules={{ required: "Please select one." }}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <div className="mb-3 three-radio-input">
                                        <Form.Check
                                          inline
                                          label="Half Finished"
                                          value="HALF_FINISHED"
                                          checked={value === "HALF_FINISHED"}
                                          onChange={(e) =>
                                            onChange(e.target.value)
                                          }
                                          name="group33"
                                          type="radio"
                                          id="half"
                                        />
                                        <Form.Check
                                          inline
                                          label="Finished"
                                          name="group33"
                                          value="FINISHED"
                                          checked={value === "FINISHED"}
                                          onChange={(e) =>
                                            onChange(e.target.value)
                                          }
                                          type="radio"
                                          id="gas"
                                        />
                                        <Form.Check
                                          inline
                                          label="Full"
                                          name="group33"
                                          value="FULL"
                                          checked={value === "FULL"}
                                          onChange={(e) =>
                                            onChange(e.target.value)
                                          }
                                          type="radio"
                                          id="oil1"
                                        />
                                      </div>
                                    )}
                                  />
                                )}
                                {errors1.basement_type && (
                                  <span style={{ color: "red" }}>
                                    {errors1.basement_type.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Kitchen</label>

                                <div className="mb-3 main-bath-check">
                                  <Form.Check
                                    inline
                                    label="Eat in"
                                    value="eat_in"
                                    {...register1("kitchen", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check1"
                                  />
                                  <Form.Check
                                    inline
                                    label="Yard access"
                                    value="yard_access"
                                    {...register1("kitchen", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check2"
                                  />
                                  <Form.Check
                                    inline
                                    label="Gallery"
                                    value="galary"
                                    {...register1("kitchen", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check3"
                                  />
                                </div>
                                {errors1.kitchen && (
                                  <span style={{ color: "red" }}>
                                    {errors1.kitchen.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Main Bath</label>

                                <div className="mb-3 main-bath-check">
                                  <Form.Check
                                    inline
                                    label="Full bath"
                                    value="full_bath"
                                    {...register1("mainBath", {
                                      required:
                                        "please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox1"
                                  />
                                  <Form.Check
                                    inline
                                    label="3 quarter"
                                    value="3_quarter"
                                    {...register1("mainBath", {
                                      required:
                                        "please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox2"
                                  />
                                  <Form.Check
                                    inline
                                    label="Bidet"
                                    value="bidet"
                                    {...register1("mainBath", {
                                      required:
                                        "please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox3"
                                  />
                                  <Form.Check
                                    inline
                                    label="Skylight"
                                    value="skylight"
                                    {...register1("mainBath", {
                                      required:
                                        "please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox4"
                                  />
                                  <Form.Check
                                    inline
                                    label="Jacuzzi"
                                    value="jacuzzi"
                                    {...register1("mainBath", {
                                      required:
                                        "please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox5"
                                  />
                                  <Form.Check
                                    inline
                                    label="Half bath"
                                    value="half_bath"
                                    {...register1("mainBath", {
                                      required:
                                        "please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox6"
                                  />
                                  <Form.Check
                                    inline
                                    label="Separate shower"
                                    value="seperate_shower"
                                    {...register1("mainBath", {
                                      required:
                                        "please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox7"
                                    className="w-100"
                                  />
                                </div>
                                {errors1.mainBath && (
                                  <span style={{ color: "red" }}>
                                    {errors1.mainBath.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Living room
                                </label>

                                <div className="mb-3 main-bath-check">
                                  <Form.Check
                                    inline
                                    label="Combination"
                                    value="combination"
                                    {...register1("livingRoom", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check11"
                                  />
                                  <Form.Check
                                    inline
                                    label="Formal"
                                    value="formal"
                                    {...register1("livingRoom", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check12"
                                  />
                                  <Form.Check
                                    inline
                                    label="Sunken"
                                    value="sunken"
                                    {...register1("livingRoom", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check13"
                                  />
                                  <Form.Check
                                    inline
                                    label="Fireplace"
                                    value="fireplace"
                                    {...register1("livingRoom", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check14"
                                  />
                                  <Form.Check
                                    inline
                                    label="Separate"
                                    value="separate"
                                    {...register1("livingRoom", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check15"
                                  />
                                </div>
                                {errors1.livingRoom && (
                                  <span style={{ color: "red" }}>
                                    {errors1.livingRoom.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Exterior</label>
                                <div className="mb-3 main-bath-check">
                                  <Form.Check
                                    inline
                                    label="Balcony"
                                    value="balcony"
                                    {...register1("exterior", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox11"
                                  />
                                  <Form.Check
                                    inline
                                    label="Grill"
                                    value="grill"
                                    {...register1("exterior", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox21"
                                  />
                                  <Form.Check
                                    inline
                                    label="Deck"
                                    value="deck"
                                    {...register1("exterior", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox31"
                                  />
                                  <Form.Check
                                    inline
                                    label="Patio"
                                    value="patio"
                                    {...register1("exterior", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox41"
                                  />
                                  <Form.Check
                                    inline
                                    label="Fence"
                                    value="fence"
                                    {...register1("exterior", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox51"
                                  />
                                  <Form.Check
                                    inline
                                    label="Shed Sprinkle system"
                                    value="shed_sprinkle_system"
                                    {...register1("exterior", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox61"
                                  />
                                  <Form.Check
                                    inline
                                    label="Garage door opener"
                                    value="garage_door_opener"
                                    {...register1("exterior", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox71"
                                    className="w-100"
                                  />
                                </div>
                                {errors1.exterior && (
                                  <span style={{ color: "red" }}>
                                    {errors1.exterior.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Master bedroom
                                </label>

                                <div className="mb-3 main-bath-check">
                                  <Form.Check
                                    inline
                                    label="Dressing room"
                                    value="dressing_room"
                                    {...register1("masterBedRoom", {
                                      required:
                                        "Please select atleast one of  them.",
                                    })}
                                    type="checkbox"
                                    id="check21"
                                  />
                                  <Form.Check
                                    inline
                                    label="Jacuzzi"
                                    value="jacuzzi"
                                    {...register1("masterBedRoom", {
                                      required:
                                        "Please select atleast one of  them.",
                                    })}
                                    type="checkbox"
                                    id="check22"
                                  />
                                  <Form.Check
                                    inline
                                    label="Fireplace"
                                    value="fireplace"
                                    {...register1("masterBedRoom", {
                                      required:
                                        "Please select atleast one of  them.",
                                    })}
                                    type="checkbox"
                                    id="check23"
                                  />
                                  <Form.Check
                                    inline
                                    label="Suite"
                                    value="suite"
                                    {...register1("masterBedRoom", {
                                      required:
                                        "Please select atleast one of  them.",
                                    })}
                                    type="checkbox"
                                    id="check24"
                                  />
                                  <Form.Check
                                    inline
                                    label="Private bath"
                                    value="private_bath"
                                    {...register1("masterBedRoom", {
                                      required:
                                        "Please select atleast one of  them.",
                                    })}
                                    type="checkbox"
                                    id="check25"
                                  />
                                  <Form.Check
                                    inline
                                    label="Walk in closet"
                                    value="walk_in_closet"
                                    {...register1("masterBedRoom", {
                                      required:
                                        "Please select atleast one of  them.",
                                    })}
                                    type="checkbox"
                                    id="check26"
                                  />
                                  <Form.Check
                                    inline
                                    label="Shared bathroom"
                                    value="shared_bathroom"
                                    {...register1("masterBedRoom", {
                                      required:
                                        "Please select atleast one of  them.",
                                    })}
                                    type="checkbox"
                                    id="check27"
                                    className="w-100"
                                  />
                                </div>
                                {errors1.masterBedRoom && (
                                  <span style={{ color: "red" }}>
                                    {errors1.masterBedRoom.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Pool</label>
                                <div className="mb-3 main-bath-check">
                                  <Form.Check
                                    inline
                                    label="Above ground"
                                    value="above_ground"
                                    {...register1("pool", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox12"
                                  />
                                  <Form.Check
                                    inline
                                    label="In ground"
                                    value="in_ground"
                                    {...register1("pool", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox22"
                                  />
                                  <Form.Check
                                    inline
                                    label="Indoor"
                                    value="indoor"
                                    {...register1("pool", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox32"
                                  />
                                  <Form.Check
                                    inline
                                    label="None/No-Pools"
                                    value="none"
                                    {...register1("pool", {
                                      onChange: (e) => {
                                        if (e.target.value === "none") {
                                          setValue1("pool", "none");
                                        } else {
                                          let lastValues = getValues1("pool");
                                          let new_values = lastValues.filter(
                                            (val) => val !== "none"
                                          );
                                          setValue1("pool", new_values);
                                        }
                                      },
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="checkbox33"
                                  />
                                </div>
                                {errors1.pool && (
                                  <span style={{ color: "red" }}>
                                    {errors1.pool.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Yard</label>

                                <div className="mb-3 main-bath-check">
                                  <Form.Check
                                    inline
                                    label="Back"
                                    value="back"
                                    {...register1("yard", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check31"
                                  />
                                  <Form.Check
                                    inline
                                    label="Side"
                                    value="side"
                                    {...register1("yard", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check32"
                                  />
                                  <Form.Check
                                    inline
                                    label="Front"
                                    value="front"
                                    {...register1("yard", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check33"
                                  />
                                  <Form.Check
                                    inline
                                    label="None"
                                    value="none"
                                    {...register1("yard", {
                                      required:
                                        "Please select atleast one of them.",
                                    })}
                                    type="checkbox"
                                    id="check34"
                                  />
                                </div>
                                {errors1.yard && (
                                  <span style={{ color: "red" }}>
                                    {errors1.yard.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">Sewer</label>
                                <Controller
                                  control={control1}
                                  name="sewer"
                                  rules={{ required: "Please select one." }}
                                  render={({ field: { onChange, value } }) => (
                                    <div className="mb-3 main-bath-check">
                                      <Form.Check
                                        inline
                                        label="City"
                                        value="CITY"
                                        checked={value === "CITY"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group244"
                                        type="radio"
                                        id="radio53"
                                      />
                                      <Form.Check
                                        inline
                                        label="Cesspool"
                                        value="CESSPOOL"
                                        checked={value === "CESSPOOL"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group244"
                                        type="radio"
                                        id="radio54"
                                      />
                                      <Form.Check
                                        inline
                                        label="Private"
                                        value="PRIVATE"
                                        checked={value === "PRIVATE"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group244"
                                        type="radio"
                                        id="radio55"
                                      />
                                      <Form.Check
                                        inline
                                        label="Peptic"
                                        value="PEPTIC"
                                        checked={value === "PEPTIC"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group244"
                                        type="radio"
                                        id="radio56"
                                      />
                                      <Form.Check
                                        inline
                                        label="Storm"
                                        value="STORM"
                                        checked={value === "STORM"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group244"
                                        type="radio"
                                        id="radio57"
                                      />
                                      <Form.Check
                                        inline
                                        label="None"
                                        value="NONE"
                                        checked={value === "NONE"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group244"
                                        type="radio"
                                        id="radio58"
                                      />
                                    </div>
                                  )}
                                />
                                {errors1.sewer && (
                                  <span style={{ color: "red" }}>
                                    {errors1.sewer.message}
                                  </span>
                                )}
                              </div>
                            </Col>

                            {/* <Col md={6}>

                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Master bedroom
                                                                </label>

                                                                <div className="mb-3 main-bath-check">
                                                                    <Form.Check
                                                                        inline
                                                                        label="Dressing room"
                                                                        value="dressing_room"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check21"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Jacuzzi"
                                                                        value="jacuzzi"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check22"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Fireplace"
                                                                        value="fireplace"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check23"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Suite"
                                                                        value="suite"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check24"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Private bath"
                                                                        value="private_bath"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check25"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Walk in closet"
                                                                        value="walk_in_closet"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check26"
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="Shared bathroom"
                                                                        value="shared_bathroom"
                                                                        {...register1("masterBedRoom", { required: 'Please select atleast one of  them.' })}
                                                                        type="checkbox"
                                                                        id="check27"
                                                                    />
                                                                </div>
                                                                {errors1.masterBedRoom && <span style={{ color: "red" }}>{errors1.masterBedRoom.message}</span>}
                                                            </div>
                                                        </Col> */}

                            {/* <Col md={6}>
                                                            <div className="my-3">
                                                                <label className="form-label">
                                                                    Yard
                                                                </label>
                                                                <Controller
                                                                    control={control1}
                                                                    name="yard"
                                                                    rules={{ required: "Please select atleast one of them." }}
                                                                    render={({ field: { onChange } }) => (
                                                                        <div className="mb-3 main-bath-check">
                                                                            <Form.Check
                                                                                inline
                                                                                label="Back"
                                                                                value="back"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                type="checkbox"
                                                                                id="check31"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Side"
                                                                                value="side"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                type="checkbox"
                                                                                id="check32"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="Front"
                                                                                value="front"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                type="checkbox"
                                                                                id="check33"
                                                                            />
                                                                            <Form.Check
                                                                                inline
                                                                                label="None"
                                                                                value="none"
                                                                                onChange={(e) => onChange(e.target.value)}
                                                                                type="checkbox"
                                                                                id="check34"
                                                                            />
                                                                        </div>)} />
                                                                {errors1.yard && <span style={{ color: "red" }}>{errors1.yard.message}</span>}
                                                            </div>
                                                        </Col> */}
                            <Col md={6}>
                              {/* <div className="my-3">
                                                                <label className="form-label"> */}

                              <div className="my-3">
                                <label className="form-label">Electric</label>
                                <Controller
                                  control={control1}
                                  name="electric"
                                  rules={{ required: "Please select one." }}
                                  render={({ field: { onChange, value } }) => (
                                    <div className="mb-3 d-flex">
                                      <Form.Check
                                        inline
                                        label="110V"
                                        value="V110"
                                        checked={value === "V110"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group241"
                                        type="radio"
                                        id="radio131"
                                      />
                                      <Form.Check
                                        inline
                                        label="220V"
                                        value="V220"
                                        checked={value === "V220"}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        name="group241"
                                        type="radio"
                                        id="radio132"
                                      />
                                    </div>
                                  )}
                                />
                                {errors1.electric && (
                                  <span style={{ color: "red" }}>
                                    {errors1.electric.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="my-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    maxLength={2000}
                                    rows={3}
                                    {...register1("description", {
                                      required: "Description is required.",
                                    })}
                                  />
                                </Form.Group>
                              </div>
                              {errors1.description && (
                                <>
                                  <span style={{ color: "red" }}>
                                    {errors1.description.message}
                                  </span>
                                  <br />
                                </>
                              )}
                              <button className="btn sign-btn fill-btn  text-white listing-submit">
                                {type == "auction"
                                  ? approved == false
                                    ? "Approve"
                                    : "Update"
                                  : "Update"}
                              </button>
                            </Col>
                          </Row>
                        </form>
                      </Tab>
                      <Tab
                        eventKey="auction"
                        disabled={type === "sale"}
                        title="AUCTION"
                      >
                        <form id="auction" onSubmit={handleSubmit(onSubmit)}>
                          <Row>
                            <Col md={12}>
                              {auctionImgUrl.length < 4 && editable == false ? (
                                <div className="my-3 text-center">
                                  <div className="upload-img">
                                    <Form.Control
                                      id="auc33"
                                      type="file"
                                      onChange={(e) => handleImageUrl(e)}
                                      onClick={(e) => (e.target.value = "")}
                                    />

                                    <img
                                      id="sale22"
                                      src={
                                        require("../../../assets/images/download.png")
                                          .default
                                      }
                                      className="upload-img"
                                    ></img>
                                    <p>
                                      Upload{" "}
                                      {globalData.userType === "seller"
                                        ? "property"
                                        : "listing"}{" "}
                                      images/banners here
                                    </p>
                                  </div>
                                  {AucImgErr && (
                                    <span style={{ color: "red" }}>
                                      {AucImgErr}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="uploaded-pictures">
                                {auctionImgUrl.map((img, index) => (
                                  <div
                                    className="picture-one-new"
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      src={img.image}
                                      className="upload-img"
                                    ></img>
                                    {editable == false && (
                                      <i
                                        class="fa fa-times"
                                        aria-hidden="true"
                                        onClick={(e) =>
                                          DeleteImages(e, index, img.id)
                                        }
                                      ></i>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="my-3">
                                <label className="form-label">
                                  Property Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  maxLength={20}
                                  disabled={editable}
                                  {...register("name", {
                                    required: "Name is required.",
                                  })}
                                  placeholder="Enter Name"
                                />
                                {errors.name && (
                                  <span style={{ color: "red" }}>
                                    {errors.name.message}
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <Controller
                                render={({
                                  field: { onChange, name, ref },
                                  fieldState: { error },
                                }) => (
                                  <div className="my-3">
                                    <label className="form-label">
                                      Address
                                    </label>
                                    <Autocomplete
                                      disabled={editable}
                                      ref={ref}
                                      apiKey="AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g"
                                      className="form-control"
                                      value={
                                        getValues("address")?.formatted_address
                                      }
                                      placeholder="eg. 857 Pineknoll St. Fort Lauderdale, FL 33308"
                                      options={{
                                        types: ["geocode", "establishment"],
                                      }}
                                      onChange={() => setValue("address", "")}
                                      onPlaceSelected={(place) =>
                                        onChange(place)
                                      }
                                    />

                                    {error?.message && (
                                      <span style={{ color: "red" }}>
                                        {error.message}
                                      </span>
                                    )}
                                  </div>
                                )}
                                control={control}
                                name="address"
                                rules={{ required: "Please select address." }}
                              />
                            </Col>
                            {auctionTypes == "online" ? (
                              <>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Property ID
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      maxLength={40}
                                      placeholder="Enter property id"
                                      {...register("propertyId", {
                                        required: "Property id is required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors.propertyId && (
                                      <span style={{ color: "red" }}>
                                        {errors.propertyId.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Sale Type
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      maxLength={20}
                                      {...register("saleType", {
                                        required: "Sale type is required.",
                                        pattern: {
                                          value: /^[A-Za-z ]*$/,
                                          message:
                                            "Only alphabets are allowed.",
                                        },
                                      })}
                                      placeholder="Enter sale type"
                                    />
                                    {errors.saleType && (
                                      <span style={{ color: "red" }}>
                                        {errors.saleType.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="my-3 auction-type">
                                    <label className="form-label">
                                      Auction Type
                                    </label>
                                    <select
                                      disabled={true}
                                      {...register("auctionType", {
                                        required: "Auction type is required.",
                                      })}
                                    >
                                      <option value="in_person">
                                        In Person
                                      </option>
                                      <option value="online">Online</option>
                                    </select>
                                    {errors.auctionType && (
                                      <span style={{ color: "red" }}>
                                        {errors.autionType.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Lot size
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      maxLength={10}
                                      placeholder="Enter lot size"
                                      {...register("lotSize", {
                                        required: "Lot size is required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors.lotSize && (
                                      <span style={{ color: "red" }}>
                                        {errors.lotSize.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Country
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      placeholder="Country Type"
                                      {...register("country", {
                                        required: "Country is required.",
                                        pattern: {
                                          value: /^[A-Za-z ]*$/,
                                          message:
                                            "Only alphabets are allowed.",
                                        },
                                      })}
                                    />
                                    {errors.country && (
                                      <span style={{ color: "red" }}>
                                        {errors.country.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Year built
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      placeholder="Enter year built"
                                      {...register("yearBuilt", {
                                        required: "Year built is required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors.yearBuilt && (
                                      <span style={{ color: "red" }}>
                                        {errors.yearBuilt.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">MLS ID</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength={20}
                                      disabled={editable}
                                      placeholder="Enter MLS id"
                                      {...register("mlsId", {
                                        required: "MLS ID is required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors.mlsId && (
                                      <span style={{ color: "red" }}>
                                        {errors.mlsId.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Bid deposit
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      maxLength={20}
                                      placeholder="Bid deposit"
                                      {...register("bidRegister", {
                                        required: "Bid deposit is required.",
                                        pattern: {
                                          value: /^[A-Za-z ]*$/,
                                          message:
                                            "Only alphabets are allowed.",
                                        },
                                      })}
                                    />
                                    {errors.bidRegister && (
                                      <span style={{ color: "red" }}>
                                        {errors.bidRegister.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">SF</label>
                                    <Row className="d-flex align-items-center">
                                      <Col md={5} className="pr-0">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id=""
                                          disabled={editable}
                                          maxLength={10}
                                          placeholder="Enter sqft"
                                          {...register("sqft", {
                                            required: "Sqft is required.",
                                            pattern: {
                                              value: /^[0-9]*$/,
                                              message:
                                                "Only numeric characters are allowed.",
                                            },
                                          })}
                                        />
                                        {errors.sqft && (
                                          <span style={{ color: "red" }}>
                                            {errors.sqft.message}
                                          </span>
                                        )}
                                      </Col>
                                      <Col md={1}>
                                        <span>X</span>
                                      </Col>
                                      <Col md={6} className="pl-0">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id=""
                                          disabled={editable}
                                          maxLength={10}
                                          placeholder="Enter dimension"
                                          {...register("dimension", {
                                            required: "Dimension is required.",
                                            pattern: {
                                              value: /^[0-9]*$/,
                                              message:
                                                "Only numeric characters are allowed.",
                                            },
                                          })}
                                        />
                                        {errors.dimension && (
                                          <span style={{ color: "red" }}>
                                            {errors.dimension.message}
                                          </span>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Bedrooms
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      maxLength={2}
                                      placeholder="Bedrooms"
                                      {...register("bedRooms", {
                                        required: "Bedrooms are required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors.bedRooms && (
                                      <span style={{ color: "red" }}>
                                        {errors.bedRooms.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">Bath</label>
                                    <Row>
                                      <Col md={6}>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id=""
                                          disabled={editable}
                                          maxLength={2}
                                          placeholder="Number of full bath"
                                          {...register("fullBath", {
                                            required: "Full bath is required.",
                                            pattern: {
                                              value: /^[0-9]*$/,
                                              message:
                                                "Only numeric characters are allowed.",
                                            },
                                          })}
                                        />
                                        {errors.fullBath && (
                                          <span style={{ color: "red" }}>
                                            {errors.fullBath.message}
                                          </span>
                                        )}
                                      </Col>
                                      <Col md={6}>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id=""
                                          maxLength={2}
                                          disabled={editable}
                                          placeholder="Number of half bath"
                                          {...register("halfBath", {
                                            required: "Half bath  is required.",
                                            pattern: {
                                              value: /^[0-9]*$/,
                                              message:
                                                "Only numeric characters are allowed.",
                                            },
                                          })}
                                        />
                                        {errors.halfBath && (
                                          <span style={{ color: "red" }}>
                                            {errors.halfBath.message}
                                          </span>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Kitchen
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength={2}
                                      id=""
                                      disabled={editable}
                                      {...register("kitchenNumber", {
                                        required: "Kitchens is required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                      placeholder="Enter number of kitchen"
                                    />
                                    {errors.kitchenNumber && (
                                      <span style={{ color: "red" }}>
                                        {errors.kitchenNumber.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Balcony
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength={2}
                                      id=""
                                      disabled={editable}
                                      {...register("balconyNumber", {
                                        required: "Balcony is required.",
                                        pattern: {
                                          value: /^[0-9]*$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                      placeholder="Enter number of balcony"
                                    />
                                    {errors.balconyNumber && (
                                      <span style={{ color: "red" }}>
                                        {errors.balconyNumber.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label>Possession Date :</label>

                                    <Controller
                                      control={control}
                                      name="possesionDate"
                                      rules={{
                                        required: "Possesion date is required.",
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <>
                                          <DatePicker
                                            disabled={editable}
                                            className="form-control"
                                            value={value}
                                            dateFormat="MM/dd/yyyy"
                                            placeholderText="MM/DD/YYYY"
                                            onKeyDown={(e) =>
                                              e.preventDefault()
                                            }
                                            minDate={new Date()}
                                            onChange={(date) =>
                                              onChange(
                                                moment(date).format(
                                                  "MM/DD/YYYY"
                                                )
                                              )
                                            }
                                          />
                                          {errors.possesionDate && (
                                            <p
                                              style={{
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {errors.possesionDate.message}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label>Start Date & Time:</label>
                                    <div className="time-box">
                                      <Controller
                                        control={control}
                                        name="startDate"
                                        rules={{
                                          required: "Start date is required.",
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <>
                                            <DatePicker
                                              disabled={editable}
                                              className="form-control"
                                              value={value}
                                              dateFormat="MM/dd/yyyy"
                                              minDate={new Date()}
                                              onChange={(date) =>
                                                onChange(
                                                  moment(date).format(
                                                    "MM/DD/YYYY"
                                                  )
                                                )
                                              }
                                            />
                                            {errors.startDate && (
                                              <p
                                                style={{
                                                  color: "red",
                                                  textAlign: "left",
                                                }}
                                              >
                                                {errors.startDate.message}
                                              </p>
                                            )}
                                          </>
                                        )}
                                      />

                                      <Controller
                                        control={control}
                                        name="startTime"
                                        rules={{
                                          required: "Start time is required.",
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <div className="ms-2">
                                            <TimePicker
                                              disabled={editable}
                                              showSecond={false}
                                              use12Hours={true}
                                              format="hh:mm a"
                                              className="form-control"
                                              onChange={(time) =>
                                                onChange(time)
                                              }
                                              inputReadOnly
                                              placeholder="00:00"
                                              value={value}
                                            />
                                            {errors.startTime && (
                                              <p style={{ color: "red" }}>
                                                {errors.startTime.message}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label>End Date & Time :</label>
                                    <div className="time-box">
                                      <Controller
                                        control={control}
                                        name="endDate"
                                        rules={{
                                          required: "End date is required.",
                                          validate: (val) =>
                                            val >= watch("startDate") ||
                                            "End date should not be less than start date.",
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <>
                                            <DatePicker
                                              disabled={editable}
                                              className="form-control"
                                              value={value}
                                              dateFormat="MM/dd/yyyy"
                                              minDate={new Date()}
                                              onChange={(date) =>
                                                onChange(
                                                  moment(date).format(
                                                    "MM/DD/YYYY"
                                                  )
                                                )
                                              }
                                            />
                                            {errors.endDate && (
                                              <p
                                                style={{
                                                  color: "red",
                                                  textAlign: "left",
                                                }}
                                              >
                                                {errors.endDate.message}
                                              </p>
                                            )}
                                          </>
                                        )}
                                      />
                                      <Controller
                                        control={control}
                                        name="endTime"
                                        rules={{
                                          required: "End time is required.",
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <div className="ms-2">
                                            <TimePicker
                                              disabled={editable}
                                              showSecond={false}
                                              use12Hours={true}
                                              format="hh:mm a"
                                              className="form-control"
                                              onChange={(time) =>
                                                onChange(time)
                                              }
                                              inputReadOnly
                                              placeholder="00:00"
                                              value={value}
                                            />
                                            {errors.endTime && (
                                              <p style={{ color: "red" }}>
                                                {errors.endTime.message}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Occupied
                                    </label>
                                    <div className="d-flex">
                                      <Controller
                                        control={control}
                                        name="occupied"
                                        rules={{
                                          required: "Occupied is required.",
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <div className="my-3 d-flex">
                                            <Form.Check
                                              inline
                                              label="Yes"
                                              name="group2"
                                              value={true}
                                              checked={value === "true"}
                                              type="radio"
                                              id="radio17"
                                              disabled={editable}
                                              onChange={(e) =>
                                                onChange(e.target.value)
                                              }
                                              style={{ width: "100px" }}
                                            />
                                            <Form.Check
                                              inline
                                              label="No"
                                              value={false}
                                              checked={value === "false"}
                                              name="group2"
                                              type="radio"
                                              id="radio27"
                                              disabled={editable}
                                              onChange={(e) =>
                                                onChange(e.target.value)
                                              }
                                            />
                                          </div>
                                        )}
                                      />
                                    </div>
                                    {errors.occupied && (
                                      <span style={{ color: "red" }}>
                                        {errors.occupied.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Cash only Purchase
                                    </label>
                                    <Controller
                                      control={control}
                                      name="cashOnlyPurchase"
                                      rules={{
                                        required:
                                          "Cash only purchase is required.",
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <div className="mb-3 d-flex">
                                          <Form.Check
                                            inline
                                            label="Yes"
                                            name="group1"
                                            value={true}
                                            checked={value === "true"}
                                            type="radio"
                                            id="radio17"
                                            disabled={editable}
                                            onChange={(e) =>
                                              onChange(e.target.value)
                                            }
                                            style={{ width: "100px" }}
                                          />
                                          <Form.Check
                                            inline
                                            label="No"
                                            value={false}
                                            checked={value === "false"}
                                            name="group1"
                                            type="radio"
                                            id="radio27"
                                            disabled={editable}
                                            onChange={(e) =>
                                              onChange(e.target.value)
                                            }
                                            // {...register('cashOnlyPurchase', { required: "Cash only purchase is required." })}
                                          />
                                        </div>
                                      )}
                                    />
                                    {errors.cashOnlyPurchase && (
                                      <span style={{ color: "red" }}>
                                        {errors.cashOnlyPurchase.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>

                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Financing available
                                    </label>
                                    <Controller
                                      control={control}
                                      name="financingAvailable"
                                      rules={{
                                        required: "Finanace is required.",
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <div className="mb-3  d-flex">
                                          <Form.Check
                                            inline
                                            label="Yes"
                                            value={true}
                                            checked={value === "true"}
                                            name="group3"
                                            type="radio"
                                            id="radio17"
                                            disabled={editable}
                                            onChange={(e) =>
                                              onChange(e.target.value)
                                            }
                                            style={{ width: "100px" }}
                                          />
                                          <Form.Check
                                            inline
                                            label="No"
                                            value={false}
                                            checked={value === "false"}
                                            name="group3"
                                            type="radio"
                                            id="radio27"
                                            disabled={editable}
                                            onChange={(e) =>
                                              onChange(e.target.value)
                                            }
                                          />
                                        </div>
                                      )}
                                    />
                                    {errors.financingAvailable && (
                                      <span style={{ color: "red" }}>
                                        {errors.financingAvailable.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                {watch("auctionType") == "online" && (
                                  <>
                                    <Col md={6}>
                                      <div className="my-3">
                                        <label className="form-label">
                                          Entry Fees ($)
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          maxLength={10}
                                          id=""
                                          disabled={editable}
                                          placeholder="Enter Entry Fees"
                                          {...register("entryFees", {
                                            required: "Entry Fees is required.",
                                            validate: (value) =>
                                              value > 50 ||
                                              "Entry Fees should be greater than $50",
                                            pattern: {
                                              value:
                                                /^\-?[0-9]+(?:\.[0-9]{1,2})?$/,
                                              message:
                                                "Only numeric characters are allowed.",
                                            },
                                          })}
                                        />
                                        {errors.entryFees && (
                                          <span style={{ color: "red" }}>
                                            {errors.entryFees.message}
                                          </span>
                                        )}
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="my-3">
                                        <label className="form-label">
                                          Base Price ($)
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          maxLength={10}
                                          id=""
                                          disabled={editable}
                                          placeholder="Enter Base Price"
                                          {...register("basePrice", {
                                            required: "Entry Base Price.",
                                            validate: (value) =>
                                              value > 50 || "Enter Base Price",
                                            pattern: {
                                              value:
                                                /^\-?[0-9]+(?:\.[0-9]{1,2})?$/,
                                              message:
                                                "Only numeric characters are allowed.",
                                            },
                                          })}
                                        />
                                        {errors.basePrice && (
                                          <span style={{ color: "red" }}>
                                            {errors.basePrice.message}
                                          </span>
                                        )}
                                      </div>
                                    </Col>
                                  </>
                                )}
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Buyers Premium ($)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength={10}
                                      id=""
                                      disabled={editable}
                                      placeholder="Enter buyer premium"
                                      {...register("buyerPremium", {
                                        required: "Buyer premium is required.",
                                        pattern: {
                                          value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors.buyerPremium && (
                                      <span style={{ color: "red" }}>
                                        {errors.buyerPremium.message}
                                      </span>
                                    )}
                                    <Form.Text
                                      style={{ fontStyle: "italic" }}
                                      className="text-muted"
                                    >
                                      *Greater of 5% of bid amount or $1000
                                    </Form.Text>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Earnest money deposit ($)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      maxLength={10}
                                      placeholder="Enter money deposit"
                                      {...register("earnestMoney", {
                                        required:
                                          "Earnest money deposit is required.",
                                        pattern: {
                                          value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors.earnestMoney && (
                                      <span style={{ color: "red" }}>
                                        {errors.earnestMoney.message}
                                      </span>
                                    )}
                                    <Form.Text
                                      style={{ fontStyle: "italic" }}
                                      className="text-muted"
                                    >
                                      *Lesser of 3.0% of bid amount of $15,000
                                    </Form.Text>
                                  </div>
                                </Col>
                              </>
                            ) : (
                              <>
                                <Col md={6}>
                                  <div className="my-3 auction-type">
                                    <label className="form-label">
                                      Auction Type
                                    </label>
                                    <select
                                      disabled={true}
                                      {...register("auctionType", {
                                        required: "Auction type is required.",
                                      })}
                                    >
                                      <option value="in_person">
                                        In Person
                                      </option>
                                      <option value="online">Online</option>
                                    </select>
                                    {errors.auctionType && (
                                      <span style={{ color: "red" }}>
                                        {errors.autionType.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Country
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      placeholder="Country Type"
                                      {...register("country", {
                                        required: "Country is required.",
                                        pattern: {
                                          value: /^[A-Za-z ]*$/,
                                          message:
                                            "Only alphabets are allowed.",
                                        },
                                      })}
                                    />
                                    {errors.country && (
                                      <span style={{ color: "red" }}>
                                        {errors.country.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label>Start Date & Time:</label>
                                    <div className="time-box">
                                      <Controller
                                        control={control}
                                        name="startDate"
                                        rules={{
                                          required: "Start date is required.",
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <>
                                            <DatePicker
                                              disabled={editable}
                                              className="form-control"
                                              value={value}
                                              dateFormat="MM/dd/yyyy"
                                              minDate={new Date()}
                                              onChange={(date) =>
                                                onChange(
                                                  moment(date).format(
                                                    "MM/DD/YYYY"
                                                  )
                                                )
                                              }
                                            />
                                            {errors.startDate && (
                                              <p
                                                style={{
                                                  color: "red",
                                                  textAlign: "left",
                                                }}
                                              >
                                                {errors.startDate.message}
                                              </p>
                                            )}
                                          </>
                                        )}
                                      />

                                      <Controller
                                        control={control}
                                        name="startTime"
                                        rules={{
                                          required: "Start time is required.",
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => (
                                          <div className="ms-2">
                                            <TimePicker
                                              disabled={editable}
                                              showSecond={false}
                                              use12Hours={true}
                                              format="hh:mm a"
                                              className="form-control"
                                              onChange={(time) =>
                                                onChange(time)
                                              }
                                              inputReadOnly
                                              placeholder="00:00"
                                              value={value}
                                            />
                                            {errors.startTime && (
                                              <p style={{ color: "red" }}>
                                                {errors.startTime.message}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                {/* <Col md={6}>
                                                                <div className="my-3">
                                                                        <label>
                                                                            Possession Date :
                                                                        </label>
                                                                    <Controller
                                                                        control={control}
                                                                        name="possesionDate"
                                                                        rules={{ required: "Possesion date is required." }}
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <>
                                                                                <DatePicker disabled={editable} className="form-control" value={value} dateFormat="MM/dd/yyyy" placeholderText="MM/DD/YYYY" onKeyDown={(e) => e.preventDefault()} minDate={new Date()} onChange={(date) => onChange(moment(date).format("MM/DD/YYYY"))} />
                                                                                {errors.possesionDate && <p style={{ color: "red", textAlign: "left" }}>{errors.possesionDate.message}</p>}
                                                                            </>

                                                                        )}
                                                                    />
                                                                </div>
                                                            </Col> */}
                                {/* <Col md={6}>
                                                                <div className="my-3">
                                                                    <label className="form-label">
                                                                        Base Price
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        maxLength={10}
                                                                        id=""
                                                                        disabled={editable}
                                                                        placeholder="Enter Base Price"
                                                                        {...register("basePrice", { required: "Entry Base Price.", validate: value => value > 50 || "Enter Base Price", pattern: { value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/, message: "Only numeric characters are allowed." } })}
                                                                    />
                                                                    {errors.basePrice && <span style={{ color: "red" }}>{errors.basePrice.message}</span>}

                                                                </div>
                                                            </Col> */}
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Total Area
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      placeholder="Total Area"
                                      {...register("totalArea", {
                                        required: "Total area is required.",
                                      })}
                                    />
                                    {errors.totalArea && (
                                      <span style={{ color: "red" }}>
                                        {errors.totalArea.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label>Auction Date & Time:</label>
                                    </div>
                                    <Controller
                                      control={control}
                                      name="auctionDate"
                                      rules={{
                                        required: "Auction date is required.",
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <div className="col-md-8">
                                          <DatePicker
                                            disabled={editable}
                                            className="form-control"
                                            value={value}
                                            dateFormat="MM/dd/yyyy"
                                            minDate={new Date()}
                                            onChange={(date) =>
                                              onChange(
                                                moment(date).format(
                                                  "MM/DD/YYYY"
                                                )
                                              )
                                            }
                                          />
                                          {errors.auctionDate && (
                                            <p
                                              style={{
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {errors.auctionDate.message}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    />

                                    <Controller
                                      control={control}
                                      name="auctionTime"
                                      rules={{
                                        required: "Auction time is required.",
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <div className="col-md-4">
                                          <TimePicker
                                            disabled={editable}
                                            showSecond={false}
                                            use12Hours={true}
                                            format="hh:mm a"
                                            className="form-control"
                                            onChange={(time) => onChange(time)}
                                            inputReadOnly
                                            placeholder="00:00"
                                            value={value}
                                          />
                                          {errors.auctionTime && (
                                            <p style={{ color: "red" }}>
                                              {errors.auctionTime.message}
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <Controller
                                    render={({
                                      field: { onChange, name, ref },
                                      fieldState: { error },
                                    }) => (
                                      <div className="my-3">
                                        <label className="form-label">
                                          Auction Location
                                        </label>
                                        <Autocomplete
                                          disabled={editable}
                                          ref={ref}
                                          apiKey="AIzaSyCTg77HJ0mIEmvnMn_lOHbd_x8QEk9zD0g"
                                          className="form-control"
                                          value={
                                            getValues("auctionLocation")
                                              ?.formatted_address
                                          }
                                          placeholder="eg. 857 Pineknoll St. Fort Lauderdale, FL 33308"
                                          options={{
                                            types: ["geocode", "establishment"],
                                          }}
                                          onChange={() =>
                                            setValue("auctionLocation", "")
                                          }
                                          onPlaceSelected={(place) =>
                                            onChange(place)
                                          }
                                        />

                                        {error?.message && (
                                          <span style={{ color: "red" }}>
                                            {error.message}
                                          </span>
                                        )}
                                      </div>
                                    )}
                                    control={control}
                                    name="auctionLocation"
                                    rules={{
                                      required:
                                        "Please select auction location.",
                                    }}
                                  />
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">Lien</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      placeholder="Lien"
                                      {...register("line", {
                                        required: "Lien is required.",
                                        pattern: {
                                          value: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/,
                                          message:
                                            "Only numeric characters are allowed.",
                                        },
                                      })}
                                    />
                                    {errors.line && (
                                      <span style={{ color: "red" }}>
                                        {errors.line.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Plaintiff
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      placeholder="Plaintiff"
                                      {...register("plaintiff", {
                                        required: "Plaintiff is required.",
                                      })}
                                    />
                                    {errors.plaintiff && (
                                      <span style={{ color: "red" }}>
                                        {errors.plaintiff.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Defendant
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      placeholder="Defendant"
                                      {...register("defendant", {
                                        required: "Defendant is required.",
                                      })}
                                    />
                                    {errors.defendant && (
                                      <span style={{ color: "red" }}>
                                        {errors.defendant.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div class="my-3">
                                    <label className="form-label">
                                      Judgement Date
                                    </label>
                                    <Controller
                                      control={control}
                                      name="judgment"
                                      rules={{
                                        required: "Judgement date is required.",
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <>
                                          <DatePicker
                                            disabled={editable}
                                            className="form-control"
                                            value={value}
                                            dateFormat="MM/dd/yyyy"
                                            placeholderText="MM/DD/YYYY"
                                            onKeyDown={(e) =>
                                              e.preventDefault()
                                            }
                                            onChange={(date) =>
                                              onChange(
                                                moment(date).format(
                                                  "MM/DD/YYYY"
                                                )
                                              )
                                            }
                                          />
                                          {errors.judgment && (
                                            <p
                                              style={{
                                                color: "red",
                                                textAlign: "left",
                                              }}
                                            >
                                              {errors.judgment.message}
                                            </p>
                                          )}
                                        </>
                                      )}
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Index Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      placeholder="Index Number"
                                      {...register("indexNumber", {
                                        required: "Index Number is required.",
                                      })}
                                    />
                                    {errors.indexNumber && (
                                      <span style={{ color: "red" }}>
                                        {errors.indexNumber.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Referee Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      placeholder="Referee Name"
                                      {...register("refereeNumber", {
                                        required: "Referee Name is required.",
                                      })}
                                    />
                                    {errors.refereeNumber && (
                                      <span style={{ color: "red" }}>
                                        {errors.refereeNumber.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Plaintiff Attorney
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      placeholder="Plaintiff Attorney"
                                      {...register("plaintiffAttorney", {
                                        required:
                                          "Plaintiff Attorney is required.",
                                      })}
                                    />
                                    {errors.plaintiffAttorney && (
                                      <span style={{ color: "red" }}>
                                        {errors.plaintiffAttorney.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="my-3">
                                    <label className="form-label">
                                      Foreclosure Type
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id=""
                                      disabled={editable}
                                      placeholder="Foreclosure Type"
                                      {...register("foreclosureType", {
                                        required:
                                          "foreclosure Type is required.",
                                      })}
                                    />
                                    {errors.foreclosureType && (
                                      <span style={{ color: "red" }}>
                                        {errors.foreclosureType.message}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div className="my-3">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="exampleForm.ControlTextarea1"
                                    >
                                      <Form.Label>Auction Note</Form.Label>
                                      <Form.Control
                                        disabled={editable}
                                        as="textarea"
                                        maxLength={600}
                                        rows={3}
                                        {...register("auctionNote", {
                                          required: "Auction note is required.",
                                        })}
                                      />
                                    </Form.Group>
                                    {errors.auctionNote && (
                                      <>
                                        <span style={{ color: "red" }}>
                                          {errors.auctionNote.message}
                                        </span>
                                        <br />
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </>
                            )}
                            <Col md={12}>
                              <div className="my-3">
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    disabled={editable}
                                    as="textarea"
                                    maxLength={2000}
                                    rows={3}
                                    {...register("description", {
                                      required: "Description is required.",
                                    })}
                                  />
                                </Form.Group>
                                {errors.description && (
                                  <>
                                    <span style={{ color: "red" }}>
                                      {errors.description.message}
                                    </span>
                                    <br />
                                  </>
                                )}
                              </div>
                              <button
                                className="btn sign-btn fill-btn  text-white listing-submit"
                                // onClick={handleSubmit(onSubmit)}
                                disabled={editable}
                              >
                                {type == "auction"
                                  ? approved == false
                                    ? "Approve"
                                    : "Update"
                                  : "Update"}
                              </button>
                            </Col>
                          </Row>
                        </form>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="text-center otp_popup_verify pt-4">
            <img
              src={require("../../../assets/images/successful.svg").default}
              alt=""
            />
            <h5 className="mt-4">
              Your {globalData.userType === "seller" ? "property" : "listing"}{" "}
              is under review.
            </h5>
            <p>
              Your {globalData.userType === "seller" ? "property" : "listing"}{" "}
              information updated.
            </p>
            <h6>Thank you</h6>
            <button
              className="btn okbtn text-white mb-4 mt-2"
              onClick={handleClose}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditProperty;
