import { useEffect, useState } from "react";
import GlobalContext from "./GlobalContext"

const GlobalState=(props)=>{
    const[name, set_name]=useState('')
    const[email, set_email]=useState('')
    const[address, set_address]=useState('')
    const[phone_number, set_phone_number]=useState('')
    const[password, set_password]=useState('')
    const[image, set_image]=useState('')
    const [id, set_id] = useState('')
    const[is_logged_in, set_is_logged_in]=useState("false")
    const[token, set_token]=useState('')
    const[stat,set_stat]=useState(0)
    const[loader, set_loader]=useState(false)
    
    useEffect(() => {
        if(sessionStorage.getItem('is_logged_in') != null && sessionStorage.getItem('is_logged_in') == "true") {
            set_name(sessionStorage.getItem('name'))
            set_email(sessionStorage.getItem('email'))
            set_address(sessionStorage.getItem('address'))
            set_phone_number(sessionStorage.getItem('phone_number'))
            set_password(sessionStorage.getItem('password'))
            set_image(sessionStorage.getItem('image'))
            set_is_logged_in(sessionStorage.getItem('is_logged_in'))
            set_token(sessionStorage.getItem('token'));
            set_id(sessionStorage.getItem("id"))
        }
    }, []);
    
    const value={
        name, set_name,
        email, set_email,
        image, set_image,
        is_logged_in, set_is_logged_in,
        password, set_password,
        phone_number, set_phone_number,
        address,set_address,
        token, set_token,
        loader,set_loader,
        stat,set_stat,
        id, set_id
    }
    
    return (
        <>
        <GlobalContext.Provider value={value}>
            {props.children}
        </GlobalContext.Provider>
        </>
    )
}

export default  GlobalState;