import React from 'react';
import Loader from 'react-loader-spinner';


export const LoadingSpinerComponent = (props) => {
    
    return (
        <>
        {props.children}
        {
            
            (props.promiseInProgress === true) ?
            <div className="main-loader">
                <div className="loader">
                    <div className="loader-div ml-5">
                        <Loader type="BallTriangle" color="#13497b" height="100" width="100" />
                    </div>
                </div>
            </div>
            :
                null
        } 
        </>
       
    )
};