import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useHistory } from "react-router-dom";
import { userService } from "../../../services/CommonServices";
import Pagination from 'react-js-pagination';
import Swal from "sweetalert2";
import GlobalContext from "../../../context/GlobalContext";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";

const PropertiesInterest = (props) => {
    var global_data = useContext(GlobalContext)
    let history = useNavigate()
    const [properties_list, set_properties_list] = useState([])
    const [page, set_page] = useState(1)
    const [total_count, set_total_count] = useState(0)

    const [search, set_search] = useState('')
    const [propertyType, setPropertyType] = useState('');
    const [status, setStatus] = useState("");

    let query = new URLSearchParams(window.location.search)

    useEffect(() => {
        var temp_page = query.get('page');
        set_page(Number(temp_page));
        if (query.get('search') != null) {
            set_search(query.get('search'));
        } else {
            set_search("");
        }

        setPropertyType(query.get("property_type"));
        setStatus(query.get("status"));
        get_properties_list(query.get("property_type"), page, search);
    }, [page, global_data.stat, propertyType, status, query.get("property_type")])

    function search_data(event) {
        if (page == 1) {
            get_properties_list(propertyType, page, search);
            handle_page_change(1);
        } else {
            set_page(1);
            handle_page_change(1);
        }
    }


    function get_properties_list(type, page, search) {
        global_data.set_loader(true)
        userService.property_interest(global_data.token, type, page, search).then(response => {
            if (response.status == 200) {
                set_properties_list(response.data?.data)
                set_total_count(response.data.totalItems)
                global_data.set_loader(false)
            }
        }).catch(err => {
            // toast.error(err.response.data.message)
            global_data.set_loader(true)
            if (err.response.status === 400) {
                toast.error(err.response.data.message)
            }
            if (err.response.status === 401) {
                global_data.set_token('')
                global_data.set_is_logged_in("false")
                localStorage.clear()
                toast.info("Your session has been expired, please login again!..")
                history('/login')
            }
        })
    }

    function handle_page_change(event) {
        set_page(event)
        history("/interests?property_type=" + propertyType + "&page=" + event + "&search=" + search)
    }

    function viewDetail(id) {
        history(`/interest_detail?id=${id}`)
    }

    function handlePropertyChange(event) {
        history("/interests?property_type=" + event.target.value + "&page=" + page + "&search=" + search)
    }

    return (

        <div class="content_area">

            <div class="tables_area">
                <div className="top-listing-header">
                    <h2 class="pull-left" style={{ marginRight: "auto" }}>Properties Interest</h2>
                    <Form.Select className="pull-right" size="lg" onChange={handlePropertyChange}>
                        <option value="auction" selected={propertyType == "auction"}>Auction (In-Person)</option>
                        <option value="sale" selected={propertyType == "sale"}>Featured</option>
                    </Form.Select>
                    <div class="pull-right searchbtn_btn">
                        <input type="text" placeholder="Search By Property Name" value={search} onChange={(event) => { set_search(event.target.value) }} />
                        <span>
                            <i class="fa fa-search" onClick={search_data}></i>
                        </span>
                    </div>
                </div>
                <div class="clear"></div>
                {properties_list.length == 0 ? <h3 className="text-center" style={{ marginTop: "40px" }}>No Data Found</h3> :
                    <div class="white_box">
                        <div class="table-responsive">

                            <table width="100%" cellspacing="0" cellpadding="0">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Property Name</th>
                                        <th>Name</th>
                                        <th>Address</th>
                                        <th>Interested Users</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {properties_list.length > 0 && properties_list?.map((item, index) =>
                                        <tr>
                                            <td> {(20 * (page - 1)) + index + 1}</td>
                                            <td><a className="item_text" onClick={(event) => { history(`/edit_property/?id=${item.id}`, { state: item.bid_count }) }}>{item.name}</a></td>
                                            <td>{item.added_by.name}</td>
                                            <td>{item.address}</td>
                                            {/* <button className="approvedd" data-toggle="tooltip" data-placement="top" title="View"  onClick={(event) => { history(`/interest_detail?id=${item.id}`) }} >{item.count}</button> */}
                                            <td><a className="text_size" onClick={(event) => { history(`/interest_detail?id=${item.id}`) }}>{item.count}</a></td>
                                        </tr>
                                    )}


                                </tbody>
                            </table>

                        </div>

                    </div>
                }
            </div>
            {properties_list.length != 0 &&
                <div class="paginationrow">
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={20}
                        totalItemsCount={total_count}
                        pageRangeDisplayed={5}
                        prevPageText="Previous"
                        nextPageText="Next"
                        onChange={(event) => { handle_page_change(event); }}
                    />

                </div>}

        </div>
    );
};
export default PropertiesInterest;