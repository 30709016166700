import { Route, Routes } from "react-router-dom";
import AgentsList from "../components/Agents/List/AgentsList";
import AddAgent from "../components/Agents/Add/AddAgent";
import ViewAgent from "../components/Agents/View/ViewAgent";
import EditAgent from "../components/Agents/Edit/EditAgent";

import SellersList from "../components/Sellers/List/SellersList";
import AddSeller from "../components/Sellers/Add/AddSeller";
import ViewSeller from "../components/Sellers/View/ViewSeller";
import EditSeller from "../components/Sellers/Edit/EditSeller";

import CustomersList from "../components/Customers/List/CustomersList";
import AddCustomer from "../components/Customers/Add/AddCustomer";
import ViewCustomer from "../components/Customers/View/ViewCustomer";
import EditCustomer from "../components/Customers/Edit/EditCustomer";

import PropertiesList from "../components/Properties/List/PropertiesList";
import AddProperty from "../components/Properties/Add/AddProperty";
import AddListing from "../components/Properties/Add/AddListing";
import ViewProperty from "../components/Properties/View/ViewProperty";
import EditProperty from "../components/Properties/Edit/EditProperty";
import NotFound from "../components/NotFound/NotFound";

import Dashboard from "../components/Dashboard/Dashboard";
import Login from "../components/Login/Login";
import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";
import ViewBid from "../components/Properties/View/BidView";
import PropertiesInterest from "../components/PropertyInterest/List/PropertyInterest";
import InterestDetail from "../components/PropertyInterest/View/InterestDetails";
import EditProfile from "../components/Profile/Edit/EditProfile";
import UserPropertiesList from "../components/Properties/List/UserPropertyListings";
import ContactUsList from "../components/ContactUs/List/ContactList";
import TransactionHistory from "../TransactionHistory/transactionHistory";
import MyPropertiesList from "../components/Properties/List/MyProperties";
import PartnerList from "../components/Partner/List/PartnerList";
import AddPartner from "../components/Partner/Add/AddPartner";

const Routing = (props) => {
  var global_data = useContext(GlobalContext);

  return (
    <Routes>
      {global_data.is_logged_in && global_data.is_logged_in == "true" ? (
        <>
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/add_agent" exact element={<AddAgent />} />
          <Route path="/view_agent" exact element={<ViewAgent />} />
          <Route path="/edit_agent" exact element={<EditAgent />} />
          <Route path="/agents" exact element={<AgentsList />} />

          <Route path="/add_seller" exact element={<AddSeller />} />
          <Route path="/view_seller" exact element={<ViewSeller />} />
          <Route path="/edit_seller" exact element={<EditSeller />} />
          <Route path="/sellers" exact element={<SellersList />} />

          <Route path="/add_customer" exact element={<AddCustomer />} />
          <Route path="/view_customer" exact element={<ViewCustomer />} />
          <Route path="/edit_customer" exact element={<EditCustomer />} />
          <Route path="/customers" exact element={<CustomersList />} />

          <Route path="/add_property" exact element={<AddProperty />} />
          <Route path="/add_listing" exact element={<AddListing />} />
          <Route path="/view_property" exact element={<ViewProperty />} />
          <Route path="/edit_property" exact element={<EditProperty />} />
          <Route path="/properties" exact element={<PropertiesList />} />
          <Route path="/my_properties" exact element={<MyPropertiesList />} />
          <Route path="/bid_detail" exact element={<ViewBid />} />
          <Route path="/interests" exact element={<PropertiesInterest />} />
          <Route path="/interest_detail" exact element={<InterestDetail />} />
          <Route path="/edit_profile" exact element={<EditProfile />} />
          <Route path="/user_property" exact element={<UserPropertiesList />} />
          <Route path="/partner" exact element={<PartnerList />} />
          <Route path="/add_partner" exact element={<AddPartner />} />
          <Route path="/contact_us" exact element={<ContactUsList />} />
          <Route
            path="/transaction_history"
            exact
            element={<TransactionHistory />}
          />
          {/* <Route element={Dashboard} /> */}
        </>
      ) : (
        <>
          <Route path="/" exact element={<Login />} />
          <Route path="/login" exact element={Login} />
          <Route element={NotFound} />
        </>
      )}
    </Routes>
  );
};

export default Routing;
