import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import validation from "../../../container/Hoc/ValidationRules";
import GlobalContext from "../../../context/GlobalContext";
import { userService } from "../../../services/CommonServices";

const AddSeller = (props) => {

    let global_data = useContext(GlobalContext)
    let history = useNavigate()
    const [image, set_image] = useState('')
    const [picture, set_picture] = useState('')
    const [states_list, set_states_list] = useState([])
    const [address_cities_list, set_address_cities_list] = useState([])
    const [email, set_email] = useState('')
    const [password, set_password] = useState('')
    const [confirm_password, set_confirm_password] = useState('')
    const [phone_number, set_phone_number] = useState('')
    const [first_name, set_first_name] = useState('')
    const [last_name, set_last_name] = useState('')
    const [address, set_address] = useState('')

    const [address_state, set_address_state] = useState('')
    const [address_city, set_address_city] = useState('')
    const [address_pin_code, set_address_pin_code] = useState('')
    const [dob, set_dob] = useState('')

    var date = new Date();
    var dt = date.toLocaleDateString('en-CA')
    var min_date = moment(date).subtract(18, 'years').format('YYYY-MM-DD')

    useEffect(() => {
        validation();
        //get_states_list();
    }, [])

    function get_states_list() {
        userService.get_states_list().then(response => {
            if (response.status === 200) {
                set_states_list(response.data);
            }
        }).catch(err => {
            toast.error(err.response.data.error)
        })
    }

    function address_state_change_handler(event) {
        set_address_city("");
        set_address_cities_list([]);
        if (event.target.value == "") {
            set_address_state("");
        } else {
            set_address_state(event.target.value);
            userService.get_city_list_by_state(event.target.value).then(response => {
                if (response.status == 200) {
                    set_address_cities_list(response.data);
                }
            }).catch(err => { console.log({ err }) })
        }
    }

    function address_city_change_handler(event) {
        set_address_city(event.target.value);
    }

    function profile_change(event) {
        if (event.target.files[0]) {
            set_picture(event.target.files[0])
            set_image(URL.createObjectURL(event.target.files[0]));
        }
    }

    function add_user(e) {
        toast.dismiss()
        e.preventDefault();

        if (picture == "") {
            toast.error("Kindly upload your profile picture.");
            return false
        }
        if (first_name.trim() == "") {
            toast.error("First name is required.");
            return false
        }
        if (last_name.trim() == "") {
            toast.error("Last name is required.");
            return false
        }
        if (email.trim() == "") {
            toast.error("Email is required.");
            return false
        } if (!/^[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,10}(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?(\.[a-z]{2,10})?$/.test(email.toLowerCase())) {
            toast.error("Invalid email")
            return false;
        }
        if (phone_number.trim() == "") {
            toast.error("Phone number is required.");
            return false
        }
        if (phone_number.trim().length < 10) {
            toast.error("Phone number should be 10 character long.")
            return false;
        }

        if (address.trim() == "") {
            toast.error("Address is required.");
            return false
        }
        if (address_state.trim() == "") {
            toast.error("Address state is required.");
            return false
        }
        if (address_city.trim() == "") {
            toast.error("Address city is required.");
            return false
        }
        if (address_pin_code.trim() == "") {
            toast.error("Address postal code is required.");
            return false
        }
        if (password.trim() == "") {
            toast.error("Password is required.");
            return false
        }
        if (password.length < 6) {
            toast.error("Password should be minimum of 6 characters long.");
            return false
        }
        if (confirm_password.trim() == "") {
            toast.error("Confirm password is required.");
            return false
        }
        if (password != confirm_password) {
            toast.error("Password and confirm password doesn't match.");
            return false
        }
        if (dob == '') {
            toast.error('Date of birth is required.')
            return false;
        }
        
        global_data.set_loader(true)
        const data = new FormData();
        data.append('first_name', first_name)
        data.append('last_name', last_name)
        data.append('email', email)
        data.append('phone_number', phone_number)
        data.append('address', address)
        data.append('address_state', address_state)
        data.append('address_city', address_city)
        data.append('address_pin_code', address_pin_code)
        data.append('password', password)
        data.append('dob', dob)
        data.append('image', picture)
        //data.append('admin_module_permissions', JSON.stringify(admin_module_permissions))

        userService.add_user(data).then(response => {
            if (response.status == 201) {
                global_data.set_loader(false)
                console.log(response.data)
                toast.success("User Added Successfully");
                history('/users')
            }
        }).catch(err => {
            global_data.set_loader(false)
            toast.error(err.response.data.error)
        })

    }
    return (
        <div class="content_area">
            <div class="tables_area">
                <h2>Add User</h2>
                <div class="white_box">
                    <div class="add_formarea">
                        <div class="row">
                            <aside class="col-md-12">
                                <div class="upload-profile">
                                    <img src={image} alt="" />
                                    <input type="file" class="upload-input" accept="image/*" onChange={(e) => profile_change(e)}></input>
                                    {image == '' ? <i class="upload-icon fa fa-camera" aria-hidden="true"></i> : ''}
                                </div>
                                <p class="add-text text-dark text-center mt-5">Add Profile</p>
                            </aside>
                        </div>
                        <div class="row form-box">
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase required">First Name</label>
                                    <input type="text" maxLength={15} value={first_name} placeholder="Enter First Name" class="form-control for_name " onChange={(e) => { set_first_name(e.target.value.trimStart()) }} />
                                </div>
                            </aside>
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase required">Last Name</label>
                                    <input type="text" maxLength={15} value={last_name} placeholder="Enter Last Name" class="form-control for_name" onChange={(e) => { set_last_name(e.target.value.trimStart()) }} />
                                </div>
                            </aside>
                        </div>
                        <div class="row">
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase required">Email</label>
                                    <input type="email" value={email} placeholder="Enter Email" class="form-control for_username" onChange={(e) => { set_email(e.target.value) }} />
                                </div>
                            </aside>
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase required">Phone Number</label>
                                    <input type="text" value={phone_number} placeholder="Enter Phone Number" minLength='1' maxLength="10" class="form-control for_numeric" onChange={(e) => { set_phone_number(e.target.value) }} />
                                </div>
                            </aside>
                        </div>
                        <div class="row">
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase required">Address</label>
                                    <input type="text" value={address} placeholder="Enter Address" class="form-control " onChange={(e) => { set_address(e.target.value.trimStart()) }} />
                                </div>
                            </aside>

                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase required">Address State</label>
                                    <select class="form-control" value={address_state} onChange={address_state_change_handler}>
                                        <option value="">--Select State--</option>
                                        {states_list.map((state, index) =>
                                            <option value={state.id}>{state.name}</option>
                                        )}
                                    </select>
                                </div>
                            </aside>
                        </div>
                        <div class="row">

                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase required">Address City</label>
                                    <select class="form-control" value={address_city} onChange={address_city_change_handler}>
                                        <option value="">--Select City--</option>
                                        {address_cities_list.map((city, index) =>
                                            <option value={city.id}>{city.name}</option>
                                        )}
                                    </select>
                                </div>
                            </aside>

                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase required">Address Postal Code</label>
                                    <input type="text" maxLength={6} value={address_pin_code} placeholder="Enter Postal Code" class="form-control for_numeric" onChange={(e) => { set_address_pin_code(e.target.value) }} />
                                </div>
                            </aside>
                        </div>
                        <div class="row">
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase required">Password</label>
                                    <input type="password" value={password} placeholder="Enter Password" class="form-control for_alpha_numeric" onChange={(e) => { set_password(e.target.value) }} />
                                </div>
                            </aside>
                            <aside class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-uppercase required">Confirm Password</label>
                                    <input type="password" value={confirm_password} placeholder="Enter Password" class="form-control for_alpha_numeric" onChange={(e) => set_confirm_password(e.target.value)} />
                                </div>
                            </aside>
                        </div>
                        <div class="row">

                            <aside class="col-sm-6 clndr1">
                                <div class="form-group">
                                    <label class="text-uppercase required">DOB</label>

                                    <div className="clndrinput">
                                        <input type="date" max={min_date} placeholder="yyyy-mm-dd" value={dob} class="form-control " onChange={(e) => { if (e.target.value > new Date) { e.preventDefault() } else { set_dob(e.target.value) } }} onKeyDown={(event) => { event.preventDefault() }} />
                                        <span>
                                            <i className="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </aside>
                        </div>

                        <button type="submit" onClick={(e) => add_user(e)} class="blue_btn yellow_btn big_yellow text-uppercase">Submit</button>

                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddSeller;