import { useContext } from "react";
import { ToastContainer } from "react-toastify";
import Layout from "../../components/Layout/Layout";
import { LoadingSpinerComponent } from "../../components/LoaderSpinner/loader";
import GlobalContext from "../../context/GlobalContext";
import Routing from "../../routes/Routing";

function App() {

  var globalContext = useContext(GlobalContext)

  return (
    <>
    <LoadingSpinerComponent promiseInProgress={globalContext.loader}>
    <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={true}
    newestOnTop={true}
    closeOnClick
    rtl={false}
    /><Layout><Routing></Routing></Layout></LoadingSpinerComponent>
    </>
  );
}

export default App;
